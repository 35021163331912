import './Form.svelte.css';
/* src/components/Form.svelte generated by Svelte v3.59.2 */
import {
	HtmlTag,
	SvelteComponent,
	add_flush_callback,
	append,
	attr,
	bind,
	binding_callbacks,
	check_outros,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	noop,
	prevent_default,
	safe_not_equal,
	set_data,
	space,
	src_url_equal,
	svg_element,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { Account, Client, Databases, ID } from '../../_snowpack/pkg/appwrite.js';
import axios from '../../_snowpack/pkg/axios.js';
import { afterUpdate, onMount } from '../../_snowpack/pkg/svelte.js';
import * as animateScroll from '../../_snowpack/pkg/svelte-scrollto.js';
import Alert from './Alert.svelte.js';
import FormField from './FormField.svelte.js';
import Loading from './Loading.svelte.js';
import Skeleton from './Skeleton.svelte.js';
import Step from './Step.svelte.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[46] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[46] = list[i];
	child_ctx[49] = list;
	child_ctx[50] = i;
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[51] = list[i];
	return child_ctx;
}

// (564:2) {#if status.isSuccessfullySent}
function create_if_block_24(ctx) {
	let alert;
	let current;

	alert = new Alert({
			props: {
				type: "success",
				customClass: "animate__animated animate__flipInX mx-auto",
				$$slots: { default: [create_default_slot_10] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(alert.$$.fragment);
		},
		m(target, anchor) {
			mount_component(alert, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const alert_changes = {};

			if (dirty[0] & /*exportedPDFs*/ 65536 | dirty[1] & /*$$scope*/ 8388608) {
				alert_changes.$$scope = { dirty, ctx };
			}

			alert.$set(alert_changes);
		},
		i(local) {
			if (current) return;
			transition_in(alert.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(alert.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(alert, detaching);
		}
	};
}

// (578:8) {#each exportedPDFs as pdf}
function create_each_block_2(ctx) {
	let a;
	let svg;
	let path;
	let t0;
	let t1_value = /*pdf*/ ctx[51].name + "";
	let t1;
	let a_href_value;
	let a_download_value;

	return {
		c() {
			a = element("a");
			svg = svg_element("svg");
			path = svg_element("path");
			t0 = space();
			t1 = text(t1_value);
			attr(path, "fill-rule", "evenodd");
			attr(path, "d", "M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z");
			attr(path, "clip-rule", "evenodd");
			attr(svg, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg, "class", "inline mr-2 h-5 w-5");
			attr(svg, "viewBox", "0 0 20 20");
			attr(svg, "fill", "currentColor");
			attr(a, "href", a_href_value = /*pdf*/ ctx[51].url);
			attr(a, "target", "_blank");
			attr(a, "class", "font-semibold flex items-center text-green-900 dark:text-green-50");
			attr(a, "download", a_download_value = "informovany-suhlas-" + /*pdf*/ ctx[51].id + ".pdf");
		},
		m(target, anchor) {
			insert(target, a, anchor);
			append(a, svg);
			append(svg, path);
			append(a, t0);
			append(a, t1);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*exportedPDFs*/ 65536 && t1_value !== (t1_value = /*pdf*/ ctx[51].name + "")) set_data(t1, t1_value);

			if (dirty[0] & /*exportedPDFs*/ 65536 && a_href_value !== (a_href_value = /*pdf*/ ctx[51].url)) {
				attr(a, "href", a_href_value);
			}

			if (dirty[0] & /*exportedPDFs*/ 65536 && a_download_value !== (a_download_value = "informovany-suhlas-" + /*pdf*/ ctx[51].id + ".pdf")) {
				attr(a, "download", a_download_value);
			}
		},
		d(detaching) {
			if (detaching) detach(a);
		}
	};
}

// (565:4) <Alert       type="success"       customClass="animate__animated animate__flipInX mx-auto"     >
function create_default_slot_10(ctx) {
	let p0;
	let t1;
	let p1;
	let t2;

	let t3_value = (Object.keys(/*exportedPDFs*/ ctx[16]).length === 1
	? 'informovaný súhlas, ktorý je potrebné vytlačiť, podpísať a priniesť v deň výletu'
	: 'informované súhlasy, ktoré je potrebné vytlačiť, podpísať a priniesť v deň konkrétneho výletu') + "";

	let t3;
	let t4;
	let t5;
	let div;
	let each_value_2 = /*exportedPDFs*/ ctx[16];
	let each_blocks = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	return {
		c() {
			p0 = element("p");
			p0.textContent = "Prihláška bola úspešne odoslaná";
			t1 = space();
			p1 = element("p");
			t2 = text("Na základe údajov vyplnených nižšie sme Vám vygenerovali ");
			t3 = text(t3_value);
			t4 = text(".");
			t5 = space();
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(p0, "class", "font-semibold");
			attr(p1, "class", "mt-1");
			attr(div, "class", "flex flex-col mt-2.5 space-y-0.5");
		},
		m(target, anchor) {
			insert(target, p0, anchor);
			insert(target, t1, anchor);
			insert(target, p1, anchor);
			append(p1, t2);
			append(p1, t3);
			append(p1, t4);
			insert(target, t5, anchor);
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div, null);
				}
			}
		},
		p(ctx, dirty) {
			if (dirty[0] & /*exportedPDFs*/ 65536 && t3_value !== (t3_value = (Object.keys(/*exportedPDFs*/ ctx[16]).length === 1
			? 'informovaný súhlas, ktorý je potrebné vytlačiť, podpísať a priniesť v deň výletu'
			: 'informované súhlasy, ktoré je potrebné vytlačiť, podpísať a priniesť v deň konkrétneho výletu') + "")) set_data(t3, t3_value);

			if (dirty[0] & /*exportedPDFs*/ 65536) {
				each_value_2 = /*exportedPDFs*/ ctx[16];
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_2(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_2.length;
			}
		},
		d(detaching) {
			if (detaching) detach(p0);
			if (detaching) detach(t1);
			if (detaching) detach(p1);
			if (detaching) detach(t5);
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (602:2) {#if status.isServerError}
function create_if_block_23(ctx) {
	let alert;
	let current;

	alert = new Alert({
			props: {
				type: "error",
				customClass: "animate__animated animate__shakeX mx-auto",
				oneline: true,
				$$slots: { default: [create_default_slot_9] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(alert.$$.fragment);
		},
		m(target, anchor) {
			mount_component(alert, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(alert.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(alert.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(alert, detaching);
		}
	};
}

// (603:4) <Alert       type="error"       customClass="animate__animated animate__shakeX mx-auto"       oneline       >
function create_default_slot_9(ctx) {
	let t;

	return {
		c() {
			t = text("Nastal problém s pripojením k databáze. Skúste to, prosím, neskôr!");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (610:2) {#if status.isCapacityReached}
function create_if_block_22(ctx) {
	let alert;
	let current;

	alert = new Alert({
			props: {
				type: "error",
				customClass: "animate__animated animate__shakeX mx-auto",
				oneline: true,
				$$slots: { default: [create_default_slot_8] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(alert.$$.fragment);
		},
		m(target, anchor) {
			mount_component(alert, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(alert.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(alert.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(alert, detaching);
		}
	};
}

// (611:4) <Alert       type="error"       customClass="animate__animated animate__shakeX mx-auto"       oneline       >
function create_default_slot_8(ctx) {
	let t;

	return {
		c() {
			t = text("Kapacita pre Vami vybraný výlet bola medzičasom naplnená. Vyberte si,\n      prosím, iný.");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (628:41) 
function create_if_block_21(ctx) {
	let alert;
	let current;

	alert = new Alert({
			props: {
				type: "error",
				customClass: "animate__animated animate__shakeX mx-auto",
				oneline: true,
				$$slots: { default: [create_default_slot_7] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(alert.$$.fragment);
		},
		m(target, anchor) {
			mount_component(alert, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(alert.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(alert.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(alert, detaching);
		}
	};
}

// (619:2) {#if status.isEmailSent}
function create_if_block_20(ctx) {
	let alert;
	let current;

	alert = new Alert({
			props: {
				type: "info",
				customClass: "animate__animated animate__flipInX mx-auto",
				oneline: true,
				$$slots: { default: [create_default_slot_6] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(alert.$$.fragment);
		},
		m(target, anchor) {
			mount_component(alert, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(alert.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(alert.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(alert, detaching);
		}
	};
}

// (629:4) <Alert       type="error"       customClass="animate__animated animate__shakeX mx-auto"       oneline       >
function create_default_slot_7(ctx) {
	let t0;
	let a;
	let t2;

	return {
		c() {
			t0 = text("Nastal problém s odoslaním emailu s podrobnými informáciami o výlete/och.\n      Kontaktujte nás emailom na ");
			a = element("a");
			a.textContent = "farakezmarok@gmail.com";
			t2 = text(".");
			attr(a, "href", "mailto:farakezmarok@gmail.com");
			attr(a, "target", "_blank");
			attr(a, "class", "text-red-900 dark:text-red-50 underline");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, a, anchor);
			insert(target, t2, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(a);
			if (detaching) detach(t2);
		}
	};
}

// (620:4) <Alert       type="info"       customClass="animate__animated animate__flipInX mx-auto"       oneline       >
function create_default_slot_6(ctx) {
	let t;

	return {
		c() {
			t = text("Email s podrobnými informáciami sme Vám odoslali na adresu uvedenú v\n      prihláške. Prosíme, prekontrolujte si aj SPAM. Doručenie môže trvať až 24\n      hodín.");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (642:2) {#if status.formEnabled === false}
function create_if_block_17(ctx) {
	let show_if;
	let show_if_1;
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block_18, create_if_block_19];
	const if_blocks = [];

	function select_block_type_1(ctx, dirty) {
		if (dirty[0] & /*startTime, time*/ 3) show_if = null;
		if (dirty[0] & /*endTime, time*/ 5) show_if_1 = null;
		if (show_if == null) show_if = !!(/*startTime*/ ctx[1] >= /*time*/ ctx[0].getTime());
		if (show_if) return 0;
		if (show_if_1 == null) show_if_1 = !!(/*endTime*/ ctx[2] <= /*time*/ ctx[0].getTime());
		if (show_if_1) return 1;
		return -1;
	}

	if (~(current_block_type_index = select_block_type_1(ctx, [-1, -1]))) {
		if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	}

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (~current_block_type_index) {
				if_blocks[current_block_type_index].m(target, anchor);
			}

			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type_1(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if (~current_block_type_index) {
					if_blocks[current_block_type_index].p(ctx, dirty);
				}
			} else {
				if (if_block) {
					group_outros();

					transition_out(if_blocks[previous_block_index], 1, 1, () => {
						if_blocks[previous_block_index] = null;
					});

					check_outros();
				}

				if (~current_block_type_index) {
					if_block = if_blocks[current_block_type_index];

					if (!if_block) {
						if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
						if_block.c();
					} else {
						if_block.p(ctx, dirty);
					}

					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				} else {
					if_block = null;
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (~current_block_type_index) {
				if_blocks[current_block_type_index].d(detaching);
			}

			if (detaching) detach(if_block_anchor);
		}
	};
}

// (657:40) 
function create_if_block_19(ctx) {
	let alert;
	let current;

	alert = new Alert({
			props: {
				type: "error",
				customClass: "my-10 animate__animated animate__shakeX",
				oneline: true,
				$$slots: { default: [create_default_slot_5] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(alert.$$.fragment);
		},
		m(target, anchor) {
			mount_component(alert, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const alert_changes = {};

			if (dirty[0] & /*endTimeFinal*/ 16 | dirty[1] & /*$$scope*/ 8388608) {
				alert_changes.$$scope = { dirty, ctx };
			}

			alert.$set(alert_changes);
		},
		i(local) {
			if (current) return;
			transition_in(alert.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(alert.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(alert, detaching);
		}
	};
}

// (643:4) {#if startTime >= time.getTime()}
function create_if_block_18(ctx) {
	let alert;
	let current;

	alert = new Alert({
			props: {
				type: "info",
				customClass: "my-10 animate__animated animate__shakeX",
				oneline: true,
				$$slots: { default: [create_default_slot_4] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(alert.$$.fragment);
		},
		m(target, anchor) {
			mount_component(alert, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const alert_changes = {};

			if (dirty[0] & /*startTimeFinal*/ 8 | dirty[1] & /*$$scope*/ 8388608) {
				alert_changes.$$scope = { dirty, ctx };
			}

			alert.$set(alert_changes);
		},
		i(local) {
			if (current) return;
			transition_in(alert.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(alert.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(alert, detaching);
		}
	};
}

// (658:6) <Alert         type="error"         customClass="my-10 animate__animated animate__shakeX"         oneline         >
function create_default_slot_5(ctx) {
	let t0;
	let t1_value = /*endTimeFinal*/ ctx[4].getDate() + "";
	let t1;
	let t2;
	let t3_value = /*endTimeFinal*/ ctx[4].getMonth() + 1 + "";
	let t3;
	let t4;
	let t5_value = /*endTimeFinal*/ ctx[4].getFullYear() + "";
	let t5;
	let t6;

	return {
		c() {
			t0 = text("Je nám to ľúto, podávanie prihlášok na letné farské výlety bolo\n        uzatvorené ku dňu ");
			t1 = text(t1_value);
			t2 = text(".\n        ");
			t3 = text(t3_value);
			t4 = text(". ");
			t5 = text(t5_value);
			t6 = text(".");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
			insert(target, t2, anchor);
			insert(target, t3, anchor);
			insert(target, t4, anchor);
			insert(target, t5, anchor);
			insert(target, t6, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*endTimeFinal*/ 16 && t1_value !== (t1_value = /*endTimeFinal*/ ctx[4].getDate() + "")) set_data(t1, t1_value);
			if (dirty[0] & /*endTimeFinal*/ 16 && t3_value !== (t3_value = /*endTimeFinal*/ ctx[4].getMonth() + 1 + "")) set_data(t3, t3_value);
			if (dirty[0] & /*endTimeFinal*/ 16 && t5_value !== (t5_value = /*endTimeFinal*/ ctx[4].getFullYear() + "")) set_data(t5, t5_value);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
			if (detaching) detach(t2);
			if (detaching) detach(t3);
			if (detaching) detach(t4);
			if (detaching) detach(t5);
			if (detaching) detach(t6);
		}
	};
}

// (644:6) <Alert         type="info"         customClass="my-10 animate__animated animate__shakeX"         oneline         >
function create_default_slot_4(ctx) {
	let t0;
	let t1_value = /*startTimeFinal*/ ctx[3].getDate() + "";
	let t1;
	let t2;
	let t3_value = /*startTimeFinal*/ ctx[3].getMonth() + 1 + "";
	let t3;
	let t4;
	let t5_value = /*startTimeFinal*/ ctx[3].getFullYear() + "";
	let t5;

	let t6_value = (/*startTimeFinal*/ ctx[3].getHours() >= 0 && /*startTimeFinal*/ ctx[3].getHours() < 10
	? ' 0'
	: ' ') + "";

	let t6;
	let t7_value = /*startTimeFinal*/ ctx[3].getHours() + "";
	let t7;

	let t8_value = (/*startTimeFinal*/ ctx[3].getMinutes() >= 0 && /*startTimeFinal*/ ctx[3].getMinutes() < 10
	? ':0'
	: ':') + "";

	let t8;
	let t9_value = /*startTimeFinal*/ ctx[3].getMinutes() + "";
	let t9;
	let t10;

	return {
		c() {
			t0 = text("Podávanie prihlášok na letné farské výlety bude dostupné od ");
			t1 = text(t1_value);
			t2 = text(".\n        ");
			t3 = text(t3_value);
			t4 = text(". ");
			t5 = text(t5_value);
			t6 = text(t6_value);
			t7 = text(t7_value);
			t8 = text(t8_value);
			t9 = text(t9_value);
			t10 = text(" hod.");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
			insert(target, t2, anchor);
			insert(target, t3, anchor);
			insert(target, t4, anchor);
			insert(target, t5, anchor);
			insert(target, t6, anchor);
			insert(target, t7, anchor);
			insert(target, t8, anchor);
			insert(target, t9, anchor);
			insert(target, t10, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*startTimeFinal*/ 8 && t1_value !== (t1_value = /*startTimeFinal*/ ctx[3].getDate() + "")) set_data(t1, t1_value);
			if (dirty[0] & /*startTimeFinal*/ 8 && t3_value !== (t3_value = /*startTimeFinal*/ ctx[3].getMonth() + 1 + "")) set_data(t3, t3_value);
			if (dirty[0] & /*startTimeFinal*/ 8 && t5_value !== (t5_value = /*startTimeFinal*/ ctx[3].getFullYear() + "")) set_data(t5, t5_value);

			if (dirty[0] & /*startTimeFinal*/ 8 && t6_value !== (t6_value = (/*startTimeFinal*/ ctx[3].getHours() >= 0 && /*startTimeFinal*/ ctx[3].getHours() < 10
			? ' 0'
			: ' ') + "")) set_data(t6, t6_value);

			if (dirty[0] & /*startTimeFinal*/ 8 && t7_value !== (t7_value = /*startTimeFinal*/ ctx[3].getHours() + "")) set_data(t7, t7_value);

			if (dirty[0] & /*startTimeFinal*/ 8 && t8_value !== (t8_value = (/*startTimeFinal*/ ctx[3].getMinutes() >= 0 && /*startTimeFinal*/ ctx[3].getMinutes() < 10
			? ':0'
			: ':') + "")) set_data(t8, t8_value);

			if (dirty[0] & /*startTimeFinal*/ 8 && t9_value !== (t9_value = /*startTimeFinal*/ ctx[3].getMinutes() + "")) set_data(t9, t9_value);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
			if (detaching) detach(t2);
			if (detaching) detach(t3);
			if (detaching) detach(t4);
			if (detaching) detach(t5);
			if (detaching) detach(t6);
			if (detaching) detach(t7);
			if (detaching) detach(t8);
			if (detaching) detach(t9);
			if (detaching) detach(t10);
		}
	};
}

// (669:2) {#if startTime >= time.getTime() || status.formEnabled === true}
function create_if_block_16(ctx) {
	let step;
	let current;

	step = new Step({
			props: {
				sectionName: "Úvodné slovo",
				sectionClass: /*steps*/ ctx[12][0],
				previousBtnClass: /*currentStep*/ ctx[11] === 0 ? 'hidden' : '',
				nextBtnClass: !/*status*/ ctx[13].formEnabled ? 'hidden' : '',
				$$slots: { default: [create_default_slot_3] },
				$$scope: { ctx }
			}
		});

	step.$on("previousStep", /*previousStep*/ ctx[17]);
	step.$on("nextStep", /*nextStep*/ ctx[18]);

	return {
		c() {
			create_component(step.$$.fragment);
		},
		m(target, anchor) {
			mount_component(step, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const step_changes = {};
			if (dirty[0] & /*steps*/ 4096) step_changes.sectionClass = /*steps*/ ctx[12][0];
			if (dirty[0] & /*currentStep*/ 2048) step_changes.previousBtnClass = /*currentStep*/ ctx[11] === 0 ? 'hidden' : '';
			if (dirty[0] & /*status*/ 8192) step_changes.nextBtnClass = !/*status*/ ctx[13].formEnabled ? 'hidden' : '';

			if (dirty[0] & /*capacity*/ 32 | dirty[1] & /*$$scope*/ 8388608) {
				step_changes.$$scope = { dirty, ctx };
			}

			step.$set(step_changes);
		},
		i(local) {
			if (current) return;
			transition_in(step.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(step.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(step, detaching);
		}
	};
}

// (671:4) <Step       sectionName="Úvodné slovo"       sectionClass={steps[0]}       previousBtnClass={currentStep === 0 ? 'hidden' : ''}       nextBtnClass={!status.formEnabled ? 'hidden' : ''}       on:previousStep={previousStep}       on:nextStep={nextStep}     >
function create_default_slot_3(ctx) {
	let figure;
	let blockquote;
	let p0;
	let t1;
	let p1;
	let t3;
	let ul;
	let t11;
	let p2;
	let t13;
	let p3;
	let t14;
	let strong2;
	let t16;
	let strong3;
	let t17;
	let t18;
	let t19;
	let strong4;
	let t21;
	let t22;
	let figcaption;

	return {
		c() {
			figure = element("figure");
			blockquote = element("blockquote");
			p0 = element("p");
			p0.textContent = "Milé deti a rodičia,";
			t1 = space();
			p1 = element("p");
			p1.textContent = "aj počas prázdnin chceme zažiť farské spoločenstvo, a preto sme si\n            pre vás pripravili v mesiaci august 2 pondelkové výlety.\n            Spoločne budeme putovať:";
			t3 = space();
			ul = element("ul");

			ul.innerHTML = `<li><svg class="text-primary dark:text-primary-light w-6 h-6 ml-0 mr-5 sm:mx-5 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path></svg> 
              <span><strong class="colorStrong svelte-1nk2iq6">5.8. </strong>púť autobusom s
                aktivitami počas cesty a so sv. omšou v Lendaku</span></li> 
            <li><svg class="text-primary dark:text-primary-light w-6 h-6 ml-0 mr-5 sm:mx-5 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path></svg> 
              <span><strong class="colorStrong svelte-1nk2iq6">12.8. </strong>kaštieľ Strážky
                – pešia púť z Kežmarku s aktivitami počas cesty a so sv. omšou v
                Strážkach</span></li>`;

			t11 = space();
			p2 = element("p");
			p2.textContent = "Na každý výlet je potrebné sa prihlásiť samostatne, nie je\n            podmienkou byť na všetkých výletoch (ale jasné, že ste pozvaní na\n            všetky :-).";
			t13 = space();
			p3 = element("p");
			t14 = text("Výlety sú určené pre deti ");
			strong2 = element("strong");
			strong2.textContent = "1. – 7. ročníka ZŠ";
			t16 = text(". Počet detí na jeden výlet je obmedzený –\n            ");
			strong3 = element("strong");
			t17 = text(/*capacity*/ ctx[5]);
			t18 = text(" účastníkov");
			t19 = text(".\n            Informácie ku každému výletu dostanete zvlášť e-mailom – najneskôr 5\n            dní pred termínom. Cena jedného výletu je\n            ");
			strong4 = element("strong");
			strong4.textContent = "5 €";
			t21 = text(" a bude sa vyberať priamo v deň\n            konania výletu pri odovzdávaní detí.");
			t22 = space();
			figcaption = element("figcaption");

			figcaption.innerHTML = `<p class="font-bold">Tešíme sa na vás!</p> 
          <cite>Vaši animátori</cite>`;

			attr(ul, "class", "py-2");
			attr(strong2, "class", "colorStrong svelte-1nk2iq6");
			attr(strong3, "class", "colorStrong svelte-1nk2iq6");
			attr(strong4, "class", "colorStrong svelte-1nk2iq6");
			attr(blockquote, "class", "space-y-4 leading-relaxed");
			attr(figcaption, "class", "mt-4 text-right");
			attr(figure, "class", "mx-auto");
		},
		m(target, anchor) {
			insert(target, figure, anchor);
			append(figure, blockquote);
			append(blockquote, p0);
			append(blockquote, t1);
			append(blockquote, p1);
			append(blockquote, t3);
			append(blockquote, ul);
			append(blockquote, t11);
			append(blockquote, p2);
			append(blockquote, t13);
			append(blockquote, p3);
			append(p3, t14);
			append(p3, strong2);
			append(p3, t16);
			append(p3, strong3);
			append(strong3, t17);
			append(strong3, t18);
			append(p3, t19);
			append(p3, strong4);
			append(p3, t21);
			append(figure, t22);
			append(figure, figcaption);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*capacity*/ 32) set_data(t17, /*capacity*/ ctx[5]);
		},
		d(detaching) {
			if (detaching) detach(figure);
		}
	};
}

// (753:2) {#if status.formEnabled === true}
function create_if_block_1(ctx) {
	let form;
	let step0;
	let t;
	let step1;
	let current;
	let mounted;
	let dispose;

	step0 = new Step({
			props: {
				sectionName: "Údaje o dieťati",
				sectionClass: /*steps*/ ctx[12][1],
				$$slots: { default: [create_default_slot_2] },
				$$scope: { ctx }
			}
		});

	step0.$on("previousStep", /*previousStep*/ ctx[17]);
	step0.$on("nextStep", /*nextStep*/ ctx[18]);

	step1 = new Step({
			props: {
				sectionName: "Údaje o rodičovi",
				sectionClass: /*steps*/ ctx[12][2],
				nextBtnType: "submit",
				nextBtnClass: "" + ((!/*checkRequiredFields*/ ctx[6]
				? 'hover:cursor-not-allowed opacity-50 notAllowed'
				: '') + (/*status*/ ctx[13].isFormSending
				? 'hover:cursor-not-allowed'
				: '') + " flex items-center"),
				nextBtnDisabled: !/*checkRequiredFields*/ ctx[6] || /*status*/ ctx[13].isFormSending,
				tooltip: "Vyplňte, prosím, všetky povinné polia v ePrihláške pred odoslaním formulára.",
				$$slots: {
					nextBtn: [create_nextBtn_slot],
					default: [create_default_slot]
				},
				$$scope: { ctx }
			}
		});

	step1.$on("previousStep", /*previousStep*/ ctx[17]);

	return {
		c() {
			form = element("form");
			create_component(step0.$$.fragment);
			t = space();
			create_component(step1.$$.fragment);
			attr(form, "name", "eprihlaska");
			attr(form, "class", "xl:w-full");
		},
		m(target, anchor) {
			insert(target, form, anchor);
			mount_component(step0, form, null);
			append(form, t);
			mount_component(step1, form, null);
			current = true;

			if (!mounted) {
				dispose = listen(form, "submit", prevent_default(/*saveData*/ ctx[19]));
				mounted = true;
			}
		},
		p(ctx, dirty) {
			const step0_changes = {};
			if (dirty[0] & /*steps*/ 4096) step0_changes.sectionClass = /*steps*/ ctx[12][1];

			if (dirty[0] & /*data, addInfo, food, drugs, allergies, trips, status*/ 59264 | dirty[1] & /*$$scope*/ 8388608) {
				step0_changes.$$scope = { dirty, ctx };
			}

			step0.$set(step0_changes);
			const step1_changes = {};
			if (dirty[0] & /*steps*/ 4096) step1_changes.sectionClass = /*steps*/ ctx[12][2];

			if (dirty[0] & /*checkRequiredFields, status*/ 8256) step1_changes.nextBtnClass = "" + ((!/*checkRequiredFields*/ ctx[6]
			? 'hover:cursor-not-allowed opacity-50 notAllowed'
			: '') + (/*status*/ ctx[13].isFormSending
			? 'hover:cursor-not-allowed'
			: '') + " flex items-center");

			if (dirty[0] & /*checkRequiredFields, status*/ 8256) step1_changes.nextBtnDisabled = !/*checkRequiredFields*/ ctx[6] || /*status*/ ctx[13].isFormSending;

			if (dirty[0] & /*status, trips, data*/ 57344 | dirty[1] & /*$$scope*/ 8388608) {
				step1_changes.$$scope = { dirty, ctx };
			}

			step1.$set(step1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(step0.$$.fragment, local);
			transition_in(step1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(step0.$$.fragment, local);
			transition_out(step1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(form);
			destroy_component(step0);
			destroy_component(step1);
			mounted = false;
			dispose();
		}
	};
}

// (787:10) {#if data.childAge && (data.childAge < 6 || data.childAge > 13)}
function create_if_block_15(ctx) {
	let p;

	return {
		c() {
			p = element("p");
			p.textContent = "Vek dieťaťa musí byť v rozmedzí od 6 do 13 rokov (vrátane)!";
			attr(p, "class", "text-red-500 font-bold");
		},
		m(target, anchor) {
			insert(target, p, anchor);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

// (810:14) {:else}
function create_else_block_1(ctx) {
	let label;
	let input;
	let input_id_value;
	let input_value_value;
	let input_disabled_value;
	let t0;
	let section0;
	let div0;
	let img;
	let img_src_value;
	let img_alt_value;
	let img_title_value;
	let t1;
	let t2;
	let div1;
	let p0;
	let t3_value = /*trip*/ ctx[46].date + "";
	let t3;
	let t4;
	let p1;
	let t5_value = /*trip*/ ctx[46].name + "";
	let t5;
	let t6;
	let section1;
	let t8;
	let label_class_value;
	let mounted;
	let dispose;

	function input_change_handler() {
		/*input_change_handler*/ ctx[22].call(input, /*each_value_1*/ ctx[49], /*trip_index_1*/ ctx[50]);
	}

	function select_block_type_3(ctx, dirty) {
		if (/*trip*/ ctx[46].isSubstitute) return create_if_block_11;
		if (/*trip*/ ctx[46].isDisabled) return create_if_block_12;
		return create_else_block_2;
	}

	let current_block_type = select_block_type_3(ctx, [-1, -1]);
	let if_block = current_block_type(ctx);

	return {
		c() {
			label = element("label");
			input = element("input");
			t0 = space();
			section0 = element("section");
			div0 = element("div");
			img = element("img");
			t1 = space();
			if_block.c();
			t2 = space();
			div1 = element("div");
			p0 = element("p");
			t3 = text(t3_value);
			t4 = space();
			p1 = element("p");
			t5 = text(t5_value);
			t6 = space();
			section1 = element("section");
			section1.innerHTML = `<p class="text-primary dark:text-primary-light font-bold text-3xl">5€</p>`;
			t8 = space();
			attr(input, "type", "checkbox");
			attr(input, "id", input_id_value = /*trip*/ ctx[46].id);
			attr(input, "class", "form-checkbox hidden");
			input.__value = input_value_value = /*trip*/ ctx[46].id;
			input.value = input.__value;
			input.disabled = input_disabled_value = /*trip*/ ctx[46].isDisabled;
			attr(img, "class", "w-[950px] h-auto object-cover");
			if (!src_url_equal(img.src, img_src_value = "/images/" + /*trip*/ ctx[46].id + ".jpg")) attr(img, "src", img_src_value);
			attr(img, "alt", img_alt_value = "Fotografia " + /*trip*/ ctx[46].name);
			attr(img, "title", img_title_value = "Fotografia " + /*trip*/ ctx[46].name);
			attr(img, "loading", "lazy");
			attr(div0, "class", "relative");
			attr(p0, "class", "text-primary dark:text-primary-light");
			attr(p1, "class", "font-semibold leading-tight");
			attr(div1, "class", "p-4 pb-6 space-y-2");
			attr(section0, "class", "bg-gray-50 dark:bg-gray-700 rounded-xl overflow-hidden flex-grow");
			attr(section1, "class", "bg-gray-50 dark:bg-gray-700 rounded-xl py-4 mt-2");

			attr(label, "class", label_class_value = "box-border mt-6 rounded-xl " + (/*trip*/ ctx[46].isDisabled
			? 'cursor-not-allowed opacity-40'
			: 'cursor-pointer') + " " + (/*trip*/ ctx[46].isChecked
			? 'ring-1 ring-offset-8 ring-primary dark:ring-primary-light dark:ring-offset-secondary-light'
			: '') + " text-center flex flex-col");
		},
		m(target, anchor) {
			insert(target, label, anchor);
			append(label, input);
			input.checked = /*trip*/ ctx[46].isChecked;
			append(label, t0);
			append(label, section0);
			append(section0, div0);
			append(div0, img);
			append(div0, t1);
			if_block.m(div0, null);
			append(section0, t2);
			append(section0, div1);
			append(div1, p0);
			append(p0, t3);
			append(div1, t4);
			append(div1, p1);
			append(p1, t5);
			append(label, t6);
			append(label, section1);
			append(label, t8);

			if (!mounted) {
				dispose = listen(input, "change", input_change_handler);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty[0] & /*trips*/ 32768 && input_id_value !== (input_id_value = /*trip*/ ctx[46].id)) {
				attr(input, "id", input_id_value);
			}

			if (dirty[0] & /*trips*/ 32768 && input_value_value !== (input_value_value = /*trip*/ ctx[46].id)) {
				input.__value = input_value_value;
				input.value = input.__value;
			}

			if (dirty[0] & /*trips*/ 32768 && input_disabled_value !== (input_disabled_value = /*trip*/ ctx[46].isDisabled)) {
				input.disabled = input_disabled_value;
			}

			if (dirty[0] & /*trips*/ 32768) {
				input.checked = /*trip*/ ctx[46].isChecked;
			}

			if (dirty[0] & /*trips*/ 32768 && !src_url_equal(img.src, img_src_value = "/images/" + /*trip*/ ctx[46].id + ".jpg")) {
				attr(img, "src", img_src_value);
			}

			if (dirty[0] & /*trips*/ 32768 && img_alt_value !== (img_alt_value = "Fotografia " + /*trip*/ ctx[46].name)) {
				attr(img, "alt", img_alt_value);
			}

			if (dirty[0] & /*trips*/ 32768 && img_title_value !== (img_title_value = "Fotografia " + /*trip*/ ctx[46].name)) {
				attr(img, "title", img_title_value);
			}

			if (current_block_type === (current_block_type = select_block_type_3(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div0, null);
				}
			}

			if (dirty[0] & /*trips*/ 32768 && t3_value !== (t3_value = /*trip*/ ctx[46].date + "")) set_data(t3, t3_value);
			if (dirty[0] & /*trips*/ 32768 && t5_value !== (t5_value = /*trip*/ ctx[46].name + "")) set_data(t5, t5_value);

			if (dirty[0] & /*trips*/ 32768 && label_class_value !== (label_class_value = "box-border mt-6 rounded-xl " + (/*trip*/ ctx[46].isDisabled
			? 'cursor-not-allowed opacity-40'
			: 'cursor-pointer') + " " + (/*trip*/ ctx[46].isChecked
			? 'ring-1 ring-offset-8 ring-primary dark:ring-primary-light dark:ring-offset-secondary-light'
			: '') + " text-center flex flex-col")) {
				attr(label, "class", label_class_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(label);
			if_block.d();
			mounted = false;
			dispose();
		}
	};
}

// (808:14) {#if !status.isTripsLoaded}
function create_if_block_10(ctx) {
	let skeleton;
	let current;
	skeleton = new Skeleton({ props: { type: "trips" } });

	return {
		c() {
			create_component(skeleton.$$.fragment);
		},
		m(target, anchor) {
			mount_component(skeleton, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(skeleton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(skeleton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(skeleton, detaching);
		}
	};
}

// (850:22) {:else}
function create_else_block_2(ctx) {
	let div;
	let span;

	function select_block_type_4(ctx, dirty) {
		if (/*trip*/ ctx[46].capacity >= 5) return create_if_block_13;
		if (/*trip*/ ctx[46].capacity >= 2 && /*trip*/ ctx[46].capacity <= 4) return create_if_block_14;
		return create_else_block_3;
	}

	let current_block_type = select_block_type_4(ctx, [-1, -1]);
	let if_block = current_block_type(ctx);

	return {
		c() {
			div = element("div");
			span = element("span");
			if_block.c();
			attr(div, "class", "text-white bg-green-500 absolute top-0 left-0 py-2 w-full font-bold uppercase text-sm");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, span);
			if_block.m(span, null);
		},
		p(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type_4(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(span, null);
				}
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			if_block.d();
		}
	};
}

// (844:48) 
function create_if_block_12(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			div.innerHTML = `<span>Kapacita naplnená</span>`;
			attr(div, "class", "text-white bg-red-500 absolute top-0 left-0 py-2 w-full font-bold uppercase text-sm");
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (838:22) {#if trip.isSubstitute}
function create_if_block_11(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			div.innerHTML = `<span>Iba ako náhradník!</span>`;
			attr(div, "class", "text-white bg-amber-500 absolute top-0 left-0 py-2 w-full font-bold uppercase text-sm");
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (859:28) {:else}
function create_else_block_3(ctx) {
	let t0_value = /*trip*/ ctx[46].capacity + "";
	let t0;
	let t1;

	return {
		c() {
			t0 = text(t0_value);
			t1 = text(" voľné miesto");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*trips*/ 32768 && t0_value !== (t0_value = /*trip*/ ctx[46].capacity + "")) set_data(t0, t0_value);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
		}
	};
}

// (857:79) 
function create_if_block_14(ctx) {
	let t0_value = /*trip*/ ctx[46].capacity + "";
	let t0;
	let t1;

	return {
		c() {
			t0 = text(t0_value);
			t1 = text(" voľné miesta");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*trips*/ 32768 && t0_value !== (t0_value = /*trip*/ ctx[46].capacity + "")) set_data(t0, t0_value);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
		}
	};
}

// (855:29) {#if trip.capacity >= 5}
function create_if_block_13(ctx) {
	let t0_value = /*trip*/ ctx[46].capacity + "";
	let t0;
	let t1;

	return {
		c() {
			t0 = text(t0_value);
			t1 = text(" voľných miest");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*trips*/ 32768 && t0_value !== (t0_value = /*trip*/ ctx[46].capacity + "")) set_data(t0, t0_value);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
		}
	};
}

// (807:12) {#each trips as trip}
function create_each_block_1(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block_10, create_else_block_1];
	const if_blocks = [];

	function select_block_type_2(ctx, dirty) {
		if (!/*status*/ ctx[13].isTripsLoaded) return 0;
		return 1;
	}

	current_block_type_index = select_block_type_2(ctx, [-1, -1]);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type_2(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (920:8) {#if allergies === 'true'}
function create_if_block_9(ctx) {
	let div;
	let formfield;
	let updating_value;
	let current;

	function formfield_value_binding(value) {
		/*formfield_value_binding*/ ctx[25](value);
	}

	let formfield_props = {
		text: "Akou alergiou trpí Vaše dieťa?",
		type: "textarea",
		placeholder: "Moje dieťa má alergiu na ....",
		customClass: "w-full"
	};

	if (/*data*/ ctx[14].allergies !== void 0) {
		formfield_props.value = /*data*/ ctx[14].allergies;
	}

	formfield = new FormField({ props: formfield_props });
	binding_callbacks.push(() => bind(formfield, 'value', formfield_value_binding));

	return {
		c() {
			div = element("div");
			create_component(formfield.$$.fragment);
			attr(div, "class", "form__input animate__animated animate__fadeIn svelte-1nk2iq6");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(formfield, div, null);
			current = true;
		},
		p(ctx, dirty) {
			const formfield_changes = {};

			if (!updating_value && dirty[0] & /*data*/ 16384) {
				updating_value = true;
				formfield_changes.value = /*data*/ ctx[14].allergies;
				add_flush_callback(() => updating_value = false);
			}

			formfield.$set(formfield_changes);
		},
		i(local) {
			if (current) return;
			transition_in(formfield.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(formfield.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(formfield);
		}
	};
}

// (964:8) {#if drugs === 'true'}
function create_if_block_8(ctx) {
	let div;
	let formfield;
	let updating_value;
	let current;

	function formfield_value_binding_1(value) {
		/*formfield_value_binding_1*/ ctx[28](value);
	}

	let formfield_props = {
		text: "Aké lieky užíva Vaše dieťa?",
		type: "textarea",
		placeholder: "Moje dieťa užíva tieto lieky .... v tomto čase ....",
		customClass: "w-full"
	};

	if (/*data*/ ctx[14].drugs !== void 0) {
		formfield_props.value = /*data*/ ctx[14].drugs;
	}

	formfield = new FormField({ props: formfield_props });
	binding_callbacks.push(() => bind(formfield, 'value', formfield_value_binding_1));

	return {
		c() {
			div = element("div");
			create_component(formfield.$$.fragment);
			attr(div, "class", "form__input animate__animated animate__fadeIn svelte-1nk2iq6");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(formfield, div, null);
			current = true;
		},
		p(ctx, dirty) {
			const formfield_changes = {};

			if (!updating_value && dirty[0] & /*data*/ 16384) {
				updating_value = true;
				formfield_changes.value = /*data*/ ctx[14].drugs;
				add_flush_callback(() => updating_value = false);
			}

			formfield.$set(formfield_changes);
		},
		i(local) {
			if (current) return;
			transition_in(formfield.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(formfield.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(formfield);
		}
	};
}

// (1007:8) {#if food === 'true'}
function create_if_block_7(ctx) {
	let div;
	let formfield;
	let updating_value;
	let current;

	function formfield_value_binding_2(value) {
		/*formfield_value_binding_2*/ ctx[31](value);
	}

	let formfield_props = {
		text: "Aké výnimky v stravovaní má Vaše dieťa?",
		type: "textarea",
		placeholder: "Moje dieťa môže jesť iba ....",
		customClass: "w-full"
	};

	if (/*data*/ ctx[14].food !== void 0) {
		formfield_props.value = /*data*/ ctx[14].food;
	}

	formfield = new FormField({ props: formfield_props });
	binding_callbacks.push(() => bind(formfield, 'value', formfield_value_binding_2));

	return {
		c() {
			div = element("div");
			create_component(formfield.$$.fragment);
			attr(div, "class", "form__input animate__animated animate__fadeIn svelte-1nk2iq6");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(formfield, div, null);
			current = true;
		},
		p(ctx, dirty) {
			const formfield_changes = {};

			if (!updating_value && dirty[0] & /*data*/ 16384) {
				updating_value = true;
				formfield_changes.value = /*data*/ ctx[14].food;
				add_flush_callback(() => updating_value = false);
			}

			formfield.$set(formfield_changes);
		},
		i(local) {
			if (current) return;
			transition_in(formfield.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(formfield.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(formfield);
		}
	};
}

// (1051:8) {#if addInfo === 'true'}
function create_if_block_6(ctx) {
	let div;
	let formfield;
	let updating_value;
	let current;

	function formfield_value_binding_3(value) {
		/*formfield_value_binding_3*/ ctx[34](value);
	}

	let formfield_props = {
		text: "Doplňujúce informácie (upozornenie pre zdravotníka)",
		type: "textarea",
		placeholder: "Moje dieťa má ťažkosti s ....",
		customClass: "w-full"
	};

	if (/*data*/ ctx[14].addInfo !== void 0) {
		formfield_props.value = /*data*/ ctx[14].addInfo;
	}

	formfield = new FormField({ props: formfield_props });
	binding_callbacks.push(() => bind(formfield, 'value', formfield_value_binding_3));

	return {
		c() {
			div = element("div");
			create_component(formfield.$$.fragment);
			attr(div, "class", "form__input animate__animated animate__fadeIn svelte-1nk2iq6");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(formfield, div, null);
			current = true;
		},
		p(ctx, dirty) {
			const formfield_changes = {};

			if (!updating_value && dirty[0] & /*data*/ 16384) {
				updating_value = true;
				formfield_changes.value = /*data*/ ctx[14].addInfo;
				add_flush_callback(() => updating_value = false);
			}

			formfield.$set(formfield_changes);
		},
		i(local) {
			if (current) return;
			transition_in(formfield.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(formfield.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(formfield);
		}
	};
}

// (760:6) <Step         sectionName="Údaje o dieťati"         sectionClass={steps[1]}         on:previousStep={previousStep}         on:nextStep={nextStep}       >
function create_default_slot_2(ctx) {
	let div0;
	let formfield0;
	let updating_value;
	let t0;
	let div1;
	let formfield1;
	let updating_value_1;
	let t1;
	let t2;
	let fieldset0;
	let legend0;
	let t5;
	let p0;
	let t7;
	let section;
	let t8;
	let fieldset1;
	let legend1;
	let t10;
	let p1;
	let t12;
	let div4;
	let div2;
	let formfield2;
	let updating_group;
	let t13;
	let div3;
	let formfield3;
	let updating_group_1;
	let t14;
	let t15;
	let fieldset2;
	let legend2;
	let t17;
	let p2;
	let t19;
	let div7;
	let div5;
	let formfield4;
	let updating_group_2;
	let t20;
	let div6;
	let formfield5;
	let updating_group_3;
	let t21;
	let t22;
	let fieldset3;
	let legend3;
	let t24;
	let p3;
	let t26;
	let div10;
	let div8;
	let formfield6;
	let updating_group_4;
	let t27;
	let div9;
	let formfield7;
	let updating_group_5;
	let t28;
	let t29;
	let fieldset4;
	let legend4;
	let t31;
	let p4;
	let t33;
	let div13;
	let div11;
	let formfield8;
	let updating_group_6;
	let t34;
	let div12;
	let formfield9;
	let updating_group_7;
	let t35;
	let t36;
	let p5;
	let current;

	function formfield0_value_binding(value) {
		/*formfield0_value_binding*/ ctx[20](value);
	}

	let formfield0_props = {
		text: "Meno a priezvisko dieťaťa",
		type: "text",
		customClass: "w-full",
		required: true
	};

	if (/*data*/ ctx[14].childName !== void 0) {
		formfield0_props.value = /*data*/ ctx[14].childName;
	}

	formfield0 = new FormField({ props: formfield0_props });
	binding_callbacks.push(() => bind(formfield0, 'value', formfield0_value_binding));

	function formfield1_value_binding(value) {
		/*formfield1_value_binding*/ ctx[21](value);
	}

	let formfield1_props = {
		text: "Vek dieťaťa",
		type: "number",
		customClass: "w-full",
		description: "Zadajte iba číselnú hodnotu (od 6 do 13), čo predstavuje od 6 do 13 rokov.",
		min: "6",
		max: "13",
		pattern: "\\d*",
		required: true
	};

	if (/*data*/ ctx[14].childAge !== void 0) {
		formfield1_props.value = /*data*/ ctx[14].childAge;
	}

	formfield1 = new FormField({ props: formfield1_props });
	binding_callbacks.push(() => bind(formfield1, 'value', formfield1_value_binding));
	let if_block0 = /*data*/ ctx[14].childAge && (/*data*/ ctx[14].childAge < 6 || /*data*/ ctx[14].childAge > 13) && create_if_block_15(ctx);
	let each_value_1 = /*trips*/ ctx[15];
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	function formfield2_group_binding(value) {
		/*formfield2_group_binding*/ ctx[23](value);
	}

	let formfield2_props = {
		type: "radio",
		text: "Áno",
		name: "allergies",
		value: "true"
	};

	if (/*allergies*/ ctx[7] !== void 0) {
		formfield2_props.group = /*allergies*/ ctx[7];
	}

	formfield2 = new FormField({ props: formfield2_props });
	binding_callbacks.push(() => bind(formfield2, 'group', formfield2_group_binding));

	function formfield3_group_binding(value) {
		/*formfield3_group_binding*/ ctx[24](value);
	}

	let formfield3_props = {
		type: "radio",
		text: "Nie",
		name: "allergies",
		value: "false"
	};

	if (/*allergies*/ ctx[7] !== void 0) {
		formfield3_props.group = /*allergies*/ ctx[7];
	}

	formfield3 = new FormField({ props: formfield3_props });
	binding_callbacks.push(() => bind(formfield3, 'group', formfield3_group_binding));
	let if_block1 = /*allergies*/ ctx[7] === 'true' && create_if_block_9(ctx);

	function formfield4_group_binding(value) {
		/*formfield4_group_binding*/ ctx[26](value);
	}

	let formfield4_props = {
		type: "radio",
		text: "Áno",
		name: "drugs",
		value: "true"
	};

	if (/*drugs*/ ctx[8] !== void 0) {
		formfield4_props.group = /*drugs*/ ctx[8];
	}

	formfield4 = new FormField({ props: formfield4_props });
	binding_callbacks.push(() => bind(formfield4, 'group', formfield4_group_binding));

	function formfield5_group_binding(value) {
		/*formfield5_group_binding*/ ctx[27](value);
	}

	let formfield5_props = {
		type: "radio",
		text: "Nie",
		name: "drugs",
		value: "false"
	};

	if (/*drugs*/ ctx[8] !== void 0) {
		formfield5_props.group = /*drugs*/ ctx[8];
	}

	formfield5 = new FormField({ props: formfield5_props });
	binding_callbacks.push(() => bind(formfield5, 'group', formfield5_group_binding));
	let if_block2 = /*drugs*/ ctx[8] === 'true' && create_if_block_8(ctx);

	function formfield6_group_binding(value) {
		/*formfield6_group_binding*/ ctx[29](value);
	}

	let formfield6_props = {
		type: "radio",
		text: "Áno",
		name: "food",
		value: "true"
	};

	if (/*food*/ ctx[9] !== void 0) {
		formfield6_props.group = /*food*/ ctx[9];
	}

	formfield6 = new FormField({ props: formfield6_props });
	binding_callbacks.push(() => bind(formfield6, 'group', formfield6_group_binding));

	function formfield7_group_binding(value) {
		/*formfield7_group_binding*/ ctx[30](value);
	}

	let formfield7_props = {
		type: "radio",
		text: "Nie",
		name: "food",
		value: "false"
	};

	if (/*food*/ ctx[9] !== void 0) {
		formfield7_props.group = /*food*/ ctx[9];
	}

	formfield7 = new FormField({ props: formfield7_props });
	binding_callbacks.push(() => bind(formfield7, 'group', formfield7_group_binding));
	let if_block3 = /*food*/ ctx[9] === 'true' && create_if_block_7(ctx);

	function formfield8_group_binding(value) {
		/*formfield8_group_binding*/ ctx[32](value);
	}

	let formfield8_props = {
		type: "radio",
		text: "Áno",
		name: "addInfo",
		value: "true"
	};

	if (/*addInfo*/ ctx[10] !== void 0) {
		formfield8_props.group = /*addInfo*/ ctx[10];
	}

	formfield8 = new FormField({ props: formfield8_props });
	binding_callbacks.push(() => bind(formfield8, 'group', formfield8_group_binding));

	function formfield9_group_binding(value) {
		/*formfield9_group_binding*/ ctx[33](value);
	}

	let formfield9_props = {
		type: "radio",
		text: "Nie",
		name: "addInfo",
		value: "false"
	};

	if (/*addInfo*/ ctx[10] !== void 0) {
		formfield9_props.group = /*addInfo*/ ctx[10];
	}

	formfield9 = new FormField({ props: formfield9_props });
	binding_callbacks.push(() => bind(formfield9, 'group', formfield9_group_binding));
	let if_block4 = /*addInfo*/ ctx[10] === 'true' && create_if_block_6(ctx);

	return {
		c() {
			div0 = element("div");
			create_component(formfield0.$$.fragment);
			t0 = space();
			div1 = element("div");
			create_component(formfield1.$$.fragment);
			t1 = space();
			if (if_block0) if_block0.c();
			t2 = space();
			fieldset0 = element("fieldset");
			legend0 = element("legend");
			legend0.innerHTML = `Výber výletov, ktorých sa dieťa zúčastní <span class="text-primary dark:text-red-500">*</span>`;
			t5 = space();
			p0 = element("p");
			p0.textContent = "Výlet je vybraný vtedy, keď sa okolo dlaždice pridá orámovanie.";
			t7 = space();
			section = element("section");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t8 = space();
			fieldset1 = element("fieldset");
			legend1 = element("legend");
			legend1.textContent = "Trpí Vaše dieťa nejakou alergiou?";
			t10 = space();
			p1 = element("p");
			p1.textContent = "Označte \"Áno\" v prípade, ak má Vaše dieťa nejaké alergie.";
			t12 = space();
			div4 = element("div");
			div2 = element("div");
			create_component(formfield2.$$.fragment);
			t13 = space();
			div3 = element("div");
			create_component(formfield3.$$.fragment);
			t14 = space();
			if (if_block1) if_block1.c();
			t15 = space();
			fieldset2 = element("fieldset");
			legend2 = element("legend");
			legend2.textContent = "Užíva Vaše dieťa nejaké lieky?";
			t17 = space();
			p2 = element("p");
			p2.textContent = "Označte \"Áno\" v prípade, ak Vaše dieťa užíva nejaké lieky, ktoré\n            musí užiť aj počas výletu. Dbajte na to, aby si všetko potrebné, čo\n            musí užívať, zobralo so sebou.";
			t19 = space();
			div7 = element("div");
			div5 = element("div");
			create_component(formfield4.$$.fragment);
			t20 = space();
			div6 = element("div");
			create_component(formfield5.$$.fragment);
			t21 = space();
			if (if_block2) if_block2.c();
			t22 = space();
			fieldset3 = element("fieldset");
			legend3 = element("legend");
			legend3.textContent = "Má Vaše dieťa výnimky v stravovaní / špeciálnu diétu?";
			t24 = space();
			p3 = element("p");
			p3.textContent = "Označte \"Áno\" v prípade, ak má Vaše dieťa výnimky v stravovaní /\n            špeciálnu diétu.";
			t26 = space();
			div10 = element("div");
			div8 = element("div");
			create_component(formfield6.$$.fragment);
			t27 = space();
			div9 = element("div");
			create_component(formfield7.$$.fragment);
			t28 = space();
			if (if_block3) if_block3.c();
			t29 = space();
			fieldset4 = element("fieldset");
			legend4 = element("legend");
			legend4.textContent = "Existujú nejaké ďalšie skutočnosti o zdravotnom stave Vášho\n            dieťaťa?";
			t31 = space();
			p4 = element("p");
			p4.textContent = "Označte \"Áno\" v prípade, ak existujú doplňujúce informácie o\n            zdravotnom stave Vášho dieťaťa, ktoré ste nezadali vyššie.";
			t33 = space();
			div13 = element("div");
			div11 = element("div");
			create_component(formfield8.$$.fragment);
			t34 = space();
			div12 = element("div");
			create_component(formfield9.$$.fragment);
			t35 = space();
			if (if_block4) if_block4.c();
			t36 = space();
			p5 = element("p");
			p5.innerHTML = `Polia označené <span class="text-primary dark:text-red-500 font-bold">*</span> sú povinné.`;
			attr(div0, "class", "form__input svelte-1nk2iq6");
			attr(div1, "class", "form__input svelte-1nk2iq6");
			attr(legend0, "class", "font-semibold text-lg");
			attr(p0, "class", "mt-1 text-sm text-gray-500 dark:text-gray-400");
			attr(section, "class", "flex flex-col w-full mx-auto sm:space-x-6 items-center sm:flex-row sm:items-stretch");
			attr(fieldset0, "class", "form__input svelte-1nk2iq6");
			attr(legend1, "class", "block text-lg font-semibold");
			attr(p1, "class", "mt-1 text-sm text-gray-500 dark:text-gray-400");
			attr(div2, "class", "flex items-center");
			attr(div3, "class", "flex items-center");
			attr(div4, "class", "mt-6 flex justify-evenly sm:flex-col sm:space-y-2");
			attr(fieldset1, "class", "form__input svelte-1nk2iq6");
			attr(legend2, "class", "block text-lg font-semibold");
			attr(p2, "class", "mt-1 text-sm text-gray-500 dark:text-gray-400");
			attr(div5, "class", "flex items-center");
			attr(div6, "class", "flex items-center");
			attr(div7, "class", "mt-6 flex justify-evenly sm:flex-col sm:space-y-2");
			attr(fieldset2, "class", "form__input svelte-1nk2iq6");
			attr(legend3, "class", "block text-lg font-semibold");
			attr(p3, "class", "mt-1 text-sm text-gray-500 dark:text-gray-400");
			attr(div8, "class", "flex items-center");
			attr(div9, "class", "flex items-center");
			attr(div10, "class", "mt-6 flex justify-evenly sm:flex-col sm:space-y-2");
			attr(fieldset3, "class", "form__input svelte-1nk2iq6");
			attr(legend4, "class", "block text-lg font-semibold");
			attr(p4, "class", "mt-1 text-sm text-gray-500 dark:text-gray-400");
			attr(div11, "class", "flex items-center");
			attr(div12, "class", "flex items-center");
			attr(div13, "class", "mt-6 flex justify-evenly sm:flex-col sm:space-y-2");
			attr(fieldset4, "class", "form__input svelte-1nk2iq6");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			mount_component(formfield0, div0, null);
			insert(target, t0, anchor);
			insert(target, div1, anchor);
			mount_component(formfield1, div1, null);
			append(div1, t1);
			if (if_block0) if_block0.m(div1, null);
			insert(target, t2, anchor);
			insert(target, fieldset0, anchor);
			append(fieldset0, legend0);
			append(fieldset0, t5);
			append(fieldset0, p0);
			append(fieldset0, t7);
			append(fieldset0, section);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(section, null);
				}
			}

			insert(target, t8, anchor);
			insert(target, fieldset1, anchor);
			append(fieldset1, legend1);
			append(fieldset1, t10);
			append(fieldset1, p1);
			append(fieldset1, t12);
			append(fieldset1, div4);
			append(div4, div2);
			mount_component(formfield2, div2, null);
			append(div4, t13);
			append(div4, div3);
			mount_component(formfield3, div3, null);
			insert(target, t14, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert(target, t15, anchor);
			insert(target, fieldset2, anchor);
			append(fieldset2, legend2);
			append(fieldset2, t17);
			append(fieldset2, p2);
			append(fieldset2, t19);
			append(fieldset2, div7);
			append(div7, div5);
			mount_component(formfield4, div5, null);
			append(div7, t20);
			append(div7, div6);
			mount_component(formfield5, div6, null);
			insert(target, t21, anchor);
			if (if_block2) if_block2.m(target, anchor);
			insert(target, t22, anchor);
			insert(target, fieldset3, anchor);
			append(fieldset3, legend3);
			append(fieldset3, t24);
			append(fieldset3, p3);
			append(fieldset3, t26);
			append(fieldset3, div10);
			append(div10, div8);
			mount_component(formfield6, div8, null);
			append(div10, t27);
			append(div10, div9);
			mount_component(formfield7, div9, null);
			insert(target, t28, anchor);
			if (if_block3) if_block3.m(target, anchor);
			insert(target, t29, anchor);
			insert(target, fieldset4, anchor);
			append(fieldset4, legend4);
			append(fieldset4, t31);
			append(fieldset4, p4);
			append(fieldset4, t33);
			append(fieldset4, div13);
			append(div13, div11);
			mount_component(formfield8, div11, null);
			append(div13, t34);
			append(div13, div12);
			mount_component(formfield9, div12, null);
			insert(target, t35, anchor);
			if (if_block4) if_block4.m(target, anchor);
			insert(target, t36, anchor);
			insert(target, p5, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const formfield0_changes = {};

			if (!updating_value && dirty[0] & /*data*/ 16384) {
				updating_value = true;
				formfield0_changes.value = /*data*/ ctx[14].childName;
				add_flush_callback(() => updating_value = false);
			}

			formfield0.$set(formfield0_changes);
			const formfield1_changes = {};

			if (!updating_value_1 && dirty[0] & /*data*/ 16384) {
				updating_value_1 = true;
				formfield1_changes.value = /*data*/ ctx[14].childAge;
				add_flush_callback(() => updating_value_1 = false);
			}

			formfield1.$set(formfield1_changes);

			if (/*data*/ ctx[14].childAge && (/*data*/ ctx[14].childAge < 6 || /*data*/ ctx[14].childAge > 13)) {
				if (if_block0) {
					
				} else {
					if_block0 = create_if_block_15(ctx);
					if_block0.c();
					if_block0.m(div1, null);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (dirty[0] & /*status, trips*/ 40960) {
				each_value_1 = /*trips*/ ctx[15];
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(section, null);
					}
				}

				group_outros();

				for (i = each_value_1.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			const formfield2_changes = {};

			if (!updating_group && dirty[0] & /*allergies*/ 128) {
				updating_group = true;
				formfield2_changes.group = /*allergies*/ ctx[7];
				add_flush_callback(() => updating_group = false);
			}

			formfield2.$set(formfield2_changes);
			const formfield3_changes = {};

			if (!updating_group_1 && dirty[0] & /*allergies*/ 128) {
				updating_group_1 = true;
				formfield3_changes.group = /*allergies*/ ctx[7];
				add_flush_callback(() => updating_group_1 = false);
			}

			formfield3.$set(formfield3_changes);

			if (/*allergies*/ ctx[7] === 'true') {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty[0] & /*allergies*/ 128) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_9(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(t15.parentNode, t15);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			const formfield4_changes = {};

			if (!updating_group_2 && dirty[0] & /*drugs*/ 256) {
				updating_group_2 = true;
				formfield4_changes.group = /*drugs*/ ctx[8];
				add_flush_callback(() => updating_group_2 = false);
			}

			formfield4.$set(formfield4_changes);
			const formfield5_changes = {};

			if (!updating_group_3 && dirty[0] & /*drugs*/ 256) {
				updating_group_3 = true;
				formfield5_changes.group = /*drugs*/ ctx[8];
				add_flush_callback(() => updating_group_3 = false);
			}

			formfield5.$set(formfield5_changes);

			if (/*drugs*/ ctx[8] === 'true') {
				if (if_block2) {
					if_block2.p(ctx, dirty);

					if (dirty[0] & /*drugs*/ 256) {
						transition_in(if_block2, 1);
					}
				} else {
					if_block2 = create_if_block_8(ctx);
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(t22.parentNode, t22);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}

			const formfield6_changes = {};

			if (!updating_group_4 && dirty[0] & /*food*/ 512) {
				updating_group_4 = true;
				formfield6_changes.group = /*food*/ ctx[9];
				add_flush_callback(() => updating_group_4 = false);
			}

			formfield6.$set(formfield6_changes);
			const formfield7_changes = {};

			if (!updating_group_5 && dirty[0] & /*food*/ 512) {
				updating_group_5 = true;
				formfield7_changes.group = /*food*/ ctx[9];
				add_flush_callback(() => updating_group_5 = false);
			}

			formfield7.$set(formfield7_changes);

			if (/*food*/ ctx[9] === 'true') {
				if (if_block3) {
					if_block3.p(ctx, dirty);

					if (dirty[0] & /*food*/ 512) {
						transition_in(if_block3, 1);
					}
				} else {
					if_block3 = create_if_block_7(ctx);
					if_block3.c();
					transition_in(if_block3, 1);
					if_block3.m(t29.parentNode, t29);
				}
			} else if (if_block3) {
				group_outros();

				transition_out(if_block3, 1, 1, () => {
					if_block3 = null;
				});

				check_outros();
			}

			const formfield8_changes = {};

			if (!updating_group_6 && dirty[0] & /*addInfo*/ 1024) {
				updating_group_6 = true;
				formfield8_changes.group = /*addInfo*/ ctx[10];
				add_flush_callback(() => updating_group_6 = false);
			}

			formfield8.$set(formfield8_changes);
			const formfield9_changes = {};

			if (!updating_group_7 && dirty[0] & /*addInfo*/ 1024) {
				updating_group_7 = true;
				formfield9_changes.group = /*addInfo*/ ctx[10];
				add_flush_callback(() => updating_group_7 = false);
			}

			formfield9.$set(formfield9_changes);

			if (/*addInfo*/ ctx[10] === 'true') {
				if (if_block4) {
					if_block4.p(ctx, dirty);

					if (dirty[0] & /*addInfo*/ 1024) {
						transition_in(if_block4, 1);
					}
				} else {
					if_block4 = create_if_block_6(ctx);
					if_block4.c();
					transition_in(if_block4, 1);
					if_block4.m(t36.parentNode, t36);
				}
			} else if (if_block4) {
				group_outros();

				transition_out(if_block4, 1, 1, () => {
					if_block4 = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(formfield0.$$.fragment, local);
			transition_in(formfield1.$$.fragment, local);

			for (let i = 0; i < each_value_1.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			transition_in(formfield2.$$.fragment, local);
			transition_in(formfield3.$$.fragment, local);
			transition_in(if_block1);
			transition_in(formfield4.$$.fragment, local);
			transition_in(formfield5.$$.fragment, local);
			transition_in(if_block2);
			transition_in(formfield6.$$.fragment, local);
			transition_in(formfield7.$$.fragment, local);
			transition_in(if_block3);
			transition_in(formfield8.$$.fragment, local);
			transition_in(formfield9.$$.fragment, local);
			transition_in(if_block4);
			current = true;
		},
		o(local) {
			transition_out(formfield0.$$.fragment, local);
			transition_out(formfield1.$$.fragment, local);
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			transition_out(formfield2.$$.fragment, local);
			transition_out(formfield3.$$.fragment, local);
			transition_out(if_block1);
			transition_out(formfield4.$$.fragment, local);
			transition_out(formfield5.$$.fragment, local);
			transition_out(if_block2);
			transition_out(formfield6.$$.fragment, local);
			transition_out(formfield7.$$.fragment, local);
			transition_out(if_block3);
			transition_out(formfield8.$$.fragment, local);
			transition_out(formfield9.$$.fragment, local);
			transition_out(if_block4);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div0);
			destroy_component(formfield0);
			if (detaching) detach(t0);
			if (detaching) detach(div1);
			destroy_component(formfield1);
			if (if_block0) if_block0.d();
			if (detaching) detach(t2);
			if (detaching) detach(fieldset0);
			destroy_each(each_blocks, detaching);
			if (detaching) detach(t8);
			if (detaching) detach(fieldset1);
			destroy_component(formfield2);
			destroy_component(formfield3);
			if (detaching) detach(t14);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach(t15);
			if (detaching) detach(fieldset2);
			destroy_component(formfield4);
			destroy_component(formfield5);
			if (detaching) detach(t21);
			if (if_block2) if_block2.d(detaching);
			if (detaching) detach(t22);
			if (detaching) detach(fieldset3);
			destroy_component(formfield6);
			destroy_component(formfield7);
			if (detaching) detach(t28);
			if (if_block3) if_block3.d(detaching);
			if (detaching) detach(t29);
			if (detaching) detach(fieldset4);
			destroy_component(formfield8);
			destroy_component(formfield9);
			if (detaching) detach(t35);
			if (if_block4) if_block4.d(detaching);
			if (detaching) detach(t36);
			if (detaching) detach(p5);
		}
	};
}

// (1124:8) {#if trips.some((trip) => trip.isChecked)}
function create_if_block_3(ctx) {
	let section;
	let t1;
	let show_if = /*trips*/ ctx[15].some(func_1);
	let t2;
	let p;
	let t3;

	let t4_value = (/*trips*/ ctx[15].filter(func_5).length === 1
	? 'objednaný výlet'
	: 'objednané výlety') + "";

	let t4;
	let t5;
	let span;
	let t6_value = /*trips*/ ctx[15].filter(func_6).length * 5 + "";
	let t6;
	let t7;
	let t8;
	let br;
	let t9;
	let html_tag;

	let raw_value = (/*trips*/ ctx[15].filter(func_7).length > 1
	? ', <u>rozdelený po 5€</u>, '
	: ' ') + "";

	let t10;
	let current;
	let if_block = show_if && create_if_block_4(ctx);

	return {
		c() {
			section = element("section");
			section.innerHTML = `<h2>Sumár</h2>`;
			t1 = space();
			if (if_block) if_block.c();
			t2 = space();
			p = element("p");
			t3 = text("Celkový paušálny poplatok za\n            ");
			t4 = text(t4_value);
			t5 = text(" je\n            ");
			span = element("span");
			t6 = text(t6_value);
			t7 = text(" €");
			t8 = text(".");
			br = element("br");
			t9 = text("\n            Tento budeme");
			html_tag = new HtmlTag(false);
			t10 = text("vyberať od prihlásených detí v deň konkrétneho výletu.");
			attr(section, "class", "text-2xl text-primary dark:text-primary-light py-2 mb-4 font-bold");
			attr(span, "class", "text-primary dark:text-primary-light text-xl");
			html_tag.a = t10;
			attr(p, "class", "font-bold text-center");
		},
		m(target, anchor) {
			insert(target, section, anchor);
			insert(target, t1, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, t2, anchor);
			insert(target, p, anchor);
			append(p, t3);
			append(p, t4);
			append(p, t5);
			append(p, span);
			append(span, t6);
			append(span, t7);
			append(p, t8);
			append(p, br);
			append(p, t9);
			html_tag.m(raw_value, p);
			append(p, t10);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty[0] & /*trips*/ 32768) show_if = /*trips*/ ctx[15].some(func_1);

			if (show_if) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty[0] & /*trips*/ 32768) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block_4(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(t2.parentNode, t2);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}

			if ((!current || dirty[0] & /*trips*/ 32768) && t4_value !== (t4_value = (/*trips*/ ctx[15].filter(func_5).length === 1
			? 'objednaný výlet'
			: 'objednané výlety') + "")) set_data(t4, t4_value);

			if ((!current || dirty[0] & /*trips*/ 32768) && t6_value !== (t6_value = /*trips*/ ctx[15].filter(func_6).length * 5 + "")) set_data(t6, t6_value);

			if ((!current || dirty[0] & /*trips*/ 32768) && raw_value !== (raw_value = (/*trips*/ ctx[15].filter(func_7).length > 1
			? ', <u>rozdelený po 5€</u>, '
			: ' ') + "")) html_tag.p(raw_value);
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(section);
			if (detaching) detach(t1);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(t2);
			if (detaching) detach(p);
		}
	};
}

// (1131:10) {#if trips.some((trip) => trip.isChecked && trip.isSubstitute)}
function create_if_block_4(ctx) {
	let alert;
	let current;

	alert = new Alert({
			props: {
				type: "warning",
				customClass: "mb-4",
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(alert.$$.fragment);
		},
		m(target, anchor) {
			mount_component(alert, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const alert_changes = {};

			if (dirty[0] & /*trips*/ 32768 | dirty[1] & /*$$scope*/ 8388608) {
				alert_changes.$$scope = { dirty, ctx };
			}

			alert.$set(alert_changes);
		},
		i(local) {
			if (current) return;
			transition_in(alert.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(alert.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(alert, detaching);
		}
	};
}

// (1145:18) {#if trip.isSubstitute && trip.isChecked}
function create_if_block_5(ctx) {
	let t0;
	let span;
	let t1_value = /*trip*/ ctx[46].name + "";
	let t1;
	let t2;
	let t3_value = (/*trips*/ ctx[15].filter(func_4).length > 1 ? ', ' : ' ') + "";
	let t3;

	return {
		c() {
			t0 = text("\"");
			span = element("span");
			t1 = text(t1_value);
			t2 = text("\"");
			t3 = text(t3_value);
			attr(span, "class", "font-semibold");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, span, anchor);
			append(span, t1);
			insert(target, t2, anchor);
			insert(target, t3, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*trips*/ 32768 && t1_value !== (t1_value = /*trip*/ ctx[46].name + "")) set_data(t1, t1_value);
			if (dirty[0] & /*trips*/ 32768 && t3_value !== (t3_value = (/*trips*/ ctx[15].filter(func_4).length > 1 ? ', ' : ' ') + "")) set_data(t3, t3_value);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(span);
			if (detaching) detach(t2);
			if (detaching) detach(t3);
		}
	};
}

// (1144:16) {#each trips as trip}
function create_each_block(ctx) {
	let t;
	let if_block = /*trip*/ ctx[46].isSubstitute && /*trip*/ ctx[46].isChecked && create_if_block_5(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			t = space();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (/*trip*/ ctx[46].isSubstitute && /*trip*/ ctx[46].isChecked) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_5(ctx);
					if_block.c();
					if_block.m(t.parentNode, t);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(t);
		}
	};
}

// (1132:12) <Alert type="warning" customClass="mb-4">
function create_default_slot_1(ctx) {
	let p0;
	let t0;

	let t1_value = (/*trips*/ ctx[15].filter(func_2).length === 1
	? 'niektorom výlete'
	: 'niektorých výletoch') + "";

	let t1;
	let t2;
	let t3;
	let p1;
	let t4;

	let t5_value = (/*trips*/ ctx[15].filter(func_3).length === 1
	? 'výlet'
	: 'výlety') + "";

	let t5;
	let t6;
	let t7;
	let span;
	let t9;
	let p2;
	let each_value = /*trips*/ ctx[15];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			p0 = element("p");
			t0 = text("V ");
			t1 = text(t1_value);
			t2 = text(" budete náhradník!");
			t3 = space();
			p1 = element("p");
			t4 = text("Na ");
			t5 = text(t5_value);
			t6 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t7 = text("sa prihlasujete\n                ");
			span = element("span");
			span.textContent = "iba ako náhradník!";
			t9 = space();
			p2 = element("p");

			p2.innerHTML = `V prípade, ak by niekto účasť zrušil, alebo by v určenom čase
                neprišiel, <u>budeme Vás kontaktovať</u>.`;

			attr(p0, "class", "font-semibold pb-1");
			attr(span, "class", "font-semibold");
		},
		m(target, anchor) {
			insert(target, p0, anchor);
			append(p0, t0);
			append(p0, t1);
			append(p0, t2);
			insert(target, t3, anchor);
			insert(target, p1, anchor);
			append(p1, t4);
			append(p1, t5);
			append(p1, t6);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(p1, null);
				}
			}

			append(p1, t7);
			append(p1, span);
			insert(target, t9, anchor);
			insert(target, p2, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*trips*/ 32768 && t1_value !== (t1_value = (/*trips*/ ctx[15].filter(func_2).length === 1
			? 'niektorom výlete'
			: 'niektorých výletoch') + "")) set_data(t1, t1_value);

			if (dirty[0] & /*trips*/ 32768 && t5_value !== (t5_value = (/*trips*/ ctx[15].filter(func_3).length === 1
			? 'výlet'
			: 'výlety') + "")) set_data(t5, t5_value);

			if (dirty[0] & /*trips*/ 32768) {
				each_value = /*trips*/ ctx[15];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(p1, t7);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d(detaching) {
			if (detaching) detach(p0);
			if (detaching) detach(t3);
			if (detaching) detach(p1);
			destroy_each(each_blocks, detaching);
			if (detaching) detach(t9);
			if (detaching) detach(p2);
		}
	};
}

// (1070:6) <Step         sectionName="Údaje o rodičovi"         sectionClass={steps[2]}         on:previousStep={previousStep}         nextBtnType="submit"         nextBtnClass="{!checkRequiredFields           ? 'hover:cursor-not-allowed opacity-50 notAllowed'           : ''}{status.isFormSending           ? 'hover:cursor-not-allowed'           : ''} flex items-center"         nextBtnDisabled={!checkRequiredFields || status.isFormSending}         tooltip="Vyplňte, prosím, všetky povinné polia v ePrihláške pred odoslaním formulára."       >
function create_default_slot(ctx) {
	let div0;
	let formfield0;
	let updating_value;
	let t0;
	let div1;
	let formfield1;
	let updating_value_1;
	let t1;
	let div2;
	let formfield2;
	let updating_value_2;
	let t2;
	let div4;
	let div3;
	let formfield3;
	let updating_checked;
	let t3;
	let show_if = /*trips*/ ctx[15].some(func);
	let if_block_anchor;
	let current;

	function formfield0_value_binding_1(value) {
		/*formfield0_value_binding_1*/ ctx[35](value);
	}

	let formfield0_props = {
		text: "Meno a priezvisko rodiča",
		type: "text",
		customClass: "w-full",
		required: true
	};

	if (/*data*/ ctx[14].parentName !== void 0) {
		formfield0_props.value = /*data*/ ctx[14].parentName;
	}

	formfield0 = new FormField({ props: formfield0_props });
	binding_callbacks.push(() => bind(formfield0, 'value', formfield0_value_binding_1));

	function formfield1_value_binding_1(value) {
		/*formfield1_value_binding_1*/ ctx[36](value);
	}

	let formfield1_props = {
		text: "Telefónne číslo rodiča",
		type: "tel",
		customClass: "w-full",
		placeholder: "0900 123 456",
		required: true,
		description: "Zadajte číslo v tvare 0900 123 456 alebo v tvare pevnej linky. Nepoužívajte medzinárodný tvar čísla (+421)."
	};

	if (/*data*/ ctx[14].parentNum !== void 0) {
		formfield1_props.value = /*data*/ ctx[14].parentNum;
	}

	formfield1 = new FormField({ props: formfield1_props });
	binding_callbacks.push(() => bind(formfield1, 'value', formfield1_value_binding_1));

	function formfield2_value_binding(value) {
		/*formfield2_value_binding*/ ctx[37](value);
	}

	let formfield2_props = {
		text: "E-mail rodiča",
		type: "email",
		customClass: "w-full",
		required: true,
		description: "Na tento e-mail sa po odoslaní prihlášky pošlú aj dodatočné informácie o výlete."
	};

	if (/*data*/ ctx[14].parentEmail !== void 0) {
		formfield2_props.value = /*data*/ ctx[14].parentEmail;
	}

	formfield2 = new FormField({ props: formfield2_props });
	binding_callbacks.push(() => bind(formfield2, 'value', formfield2_value_binding));

	function formfield3_checked_binding(value) {
		/*formfield3_checked_binding*/ ctx[38](value);
	}

	let formfield3_props = {
		text: "Súhlasím so spracovaním mojich osobných údajov ako aj osobných údajov môjho dieťaťa na účely odoslania ePrihlášky do systému a následného vyhotovenia zoznamu účastníkov.",
		id: "consent",
		type: "checkbox",
		required: true
	};

	if (/*data*/ ctx[14].consent !== void 0) {
		formfield3_props.checked = /*data*/ ctx[14].consent;
	}

	formfield3 = new FormField({ props: formfield3_props });
	binding_callbacks.push(() => bind(formfield3, 'checked', formfield3_checked_binding));
	let if_block = show_if && create_if_block_3(ctx);

	return {
		c() {
			div0 = element("div");
			create_component(formfield0.$$.fragment);
			t0 = space();
			div1 = element("div");
			create_component(formfield1.$$.fragment);
			t1 = space();
			div2 = element("div");
			create_component(formfield2.$$.fragment);
			t2 = space();
			div4 = element("div");
			div3 = element("div");
			create_component(formfield3.$$.fragment);
			t3 = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
			attr(div0, "class", "form__input svelte-1nk2iq6");
			attr(div1, "class", "form__input svelte-1nk2iq6");
			attr(div2, "class", "form__input svelte-1nk2iq6");
			attr(div3, "class", "inline align-middle");
			attr(div4, "class", "form__input svelte-1nk2iq6");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			mount_component(formfield0, div0, null);
			insert(target, t0, anchor);
			insert(target, div1, anchor);
			mount_component(formfield1, div1, null);
			insert(target, t1, anchor);
			insert(target, div2, anchor);
			mount_component(formfield2, div2, null);
			insert(target, t2, anchor);
			insert(target, div4, anchor);
			append(div4, div3);
			mount_component(formfield3, div3, null);
			insert(target, t3, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const formfield0_changes = {};

			if (!updating_value && dirty[0] & /*data*/ 16384) {
				updating_value = true;
				formfield0_changes.value = /*data*/ ctx[14].parentName;
				add_flush_callback(() => updating_value = false);
			}

			formfield0.$set(formfield0_changes);
			const formfield1_changes = {};

			if (!updating_value_1 && dirty[0] & /*data*/ 16384) {
				updating_value_1 = true;
				formfield1_changes.value = /*data*/ ctx[14].parentNum;
				add_flush_callback(() => updating_value_1 = false);
			}

			formfield1.$set(formfield1_changes);
			const formfield2_changes = {};

			if (!updating_value_2 && dirty[0] & /*data*/ 16384) {
				updating_value_2 = true;
				formfield2_changes.value = /*data*/ ctx[14].parentEmail;
				add_flush_callback(() => updating_value_2 = false);
			}

			formfield2.$set(formfield2_changes);
			const formfield3_changes = {};

			if (!updating_checked && dirty[0] & /*data*/ 16384) {
				updating_checked = true;
				formfield3_changes.checked = /*data*/ ctx[14].consent;
				add_flush_callback(() => updating_checked = false);
			}

			formfield3.$set(formfield3_changes);
			if (dirty[0] & /*trips*/ 32768) show_if = /*trips*/ ctx[15].some(func);

			if (show_if) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty[0] & /*trips*/ 32768) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block_3(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(formfield0.$$.fragment, local);
			transition_in(formfield1.$$.fragment, local);
			transition_in(formfield2.$$.fragment, local);
			transition_in(formfield3.$$.fragment, local);
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(formfield0.$$.fragment, local);
			transition_out(formfield1.$$.fragment, local);
			transition_out(formfield2.$$.fragment, local);
			transition_out(formfield3.$$.fragment, local);
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div0);
			destroy_component(formfield0);
			if (detaching) detach(t0);
			if (detaching) detach(div1);
			destroy_component(formfield1);
			if (detaching) detach(t1);
			if (detaching) detach(div2);
			destroy_component(formfield2);
			if (detaching) detach(t2);
			if (detaching) detach(div4);
			destroy_component(formfield3);
			if (detaching) detach(t3);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (1199:10) {:else}
function create_else_block(ctx) {
	let t;

	return {
		c() {
			t = text("Odoslať prihlášku");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (1178:10) {#if status.isFormSending}
function create_if_block_2(ctx) {
	let svg;
	let circle;
	let path;
	let t;

	return {
		c() {
			svg = svg_element("svg");
			circle = svg_element("circle");
			path = svg_element("path");
			t = text(" Odosielam...");
			attr(circle, "class", "opacity-25");
			attr(circle, "cx", "12");
			attr(circle, "cy", "12");
			attr(circle, "r", "10");
			attr(circle, "stroke", "currentColor");
			attr(circle, "stroke-width", "4");
			attr(path, "class", "opacity-75");
			attr(path, "fill", "currentColor");
			attr(path, "d", "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z");
			attr(svg, "class", "animate-spin -ml-1 mr-3 h-5 w-5 text-white");
			attr(svg, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg, "fill", "none");
			attr(svg, "viewBox", "0 0 24 24");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, circle);
			append(svg, path);
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(svg);
			if (detaching) detach(t);
		}
	};
}

// (1177:8) 
function create_nextBtn_slot(ctx) {
	let span;

	function select_block_type_5(ctx, dirty) {
		if (/*status*/ ctx[13].isFormSending) return create_if_block_2;
		return create_else_block;
	}

	let current_block_type = select_block_type_5(ctx, [-1, -1]);
	let if_block = current_block_type(ctx);

	return {
		c() {
			span = element("span");
			if_block.c();
			attr(span, "slot", "nextBtn");
			attr(span, "class", "flex items-center");
		},
		m(target, anchor) {
			insert(target, span, anchor);
			if_block.m(span, null);
		},
		p(ctx, dirty) {
			if (current_block_type !== (current_block_type = select_block_type_5(ctx, dirty))) {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(span, null);
				}
			}
		},
		d(detaching) {
			if (detaching) detach(span);
			if_block.d();
		}
	};
}

// (1208:0) {#if status.formEnabled !== true && status.formEnabled !== false}
function create_if_block(ctx) {
	let loading;
	let current;

	loading = new Loading({
			props: {
				heading: "Načítavam ePrihlášku...",
				description: "Môže to trvať niekoľko sekúnd, prosím, nezatvárajte toto okno."
			}
		});

	return {
		c() {
			create_component(loading.$$.fragment);
		},
		m(target, anchor) {
			mount_component(loading, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(loading.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(loading.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(loading, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let t0;
	let t1;
	let t2;
	let current_block_type_index;
	let if_block3;
	let t3;
	let t4;
	let show_if = /*startTime*/ ctx[1] >= /*time*/ ctx[0].getTime() || /*status*/ ctx[13].formEnabled === true;
	let t5;
	let t6;
	let if_block7_anchor;
	let current;
	let if_block0 = /*status*/ ctx[13].isSuccessfullySent && create_if_block_24(ctx);
	let if_block1 = /*status*/ ctx[13].isServerError && create_if_block_23(ctx);
	let if_block2 = /*status*/ ctx[13].isCapacityReached && create_if_block_22(ctx);
	const if_block_creators = [create_if_block_20, create_if_block_21];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*status*/ ctx[13].isEmailSent) return 0;
		if (/*status*/ ctx[13].isEmailSent === false) return 1;
		return -1;
	}

	if (~(current_block_type_index = select_block_type(ctx, [-1, -1]))) {
		if_block3 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	}

	let if_block4 = /*status*/ ctx[13].formEnabled === false && create_if_block_17(ctx);
	let if_block5 = show_if && create_if_block_16(ctx);
	let if_block6 = /*status*/ ctx[13].formEnabled === true && create_if_block_1(ctx);
	let if_block7 = /*status*/ ctx[13].formEnabled !== true && /*status*/ ctx[13].formEnabled !== false && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			if (if_block0) if_block0.c();
			t0 = space();
			if (if_block1) if_block1.c();
			t1 = space();
			if (if_block2) if_block2.c();
			t2 = space();
			if (if_block3) if_block3.c();
			t3 = space();
			if (if_block4) if_block4.c();
			t4 = space();
			if (if_block5) if_block5.c();
			t5 = space();
			if (if_block6) if_block6.c();
			t6 = space();
			if (if_block7) if_block7.c();
			if_block7_anchor = empty();
			attr(div, "class", "xl:w-2/3 xl:mx-auto");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block0) if_block0.m(div, null);
			append(div, t0);
			if (if_block1) if_block1.m(div, null);
			append(div, t1);
			if (if_block2) if_block2.m(div, null);
			append(div, t2);

			if (~current_block_type_index) {
				if_blocks[current_block_type_index].m(div, null);
			}

			append(div, t3);
			if (if_block4) if_block4.m(div, null);
			append(div, t4);
			if (if_block5) if_block5.m(div, null);
			append(div, t5);
			if (if_block6) if_block6.m(div, null);
			insert(target, t6, anchor);
			if (if_block7) if_block7.m(target, anchor);
			insert(target, if_block7_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (/*status*/ ctx[13].isSuccessfullySent) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty[0] & /*status*/ 8192) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_24(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div, t0);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (/*status*/ ctx[13].isServerError) {
				if (if_block1) {
					if (dirty[0] & /*status*/ 8192) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_23(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div, t1);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (/*status*/ ctx[13].isCapacityReached) {
				if (if_block2) {
					if (dirty[0] & /*status*/ 8192) {
						transition_in(if_block2, 1);
					}
				} else {
					if_block2 = create_if_block_22(ctx);
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(div, t2);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}

			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index !== previous_block_index) {
				if (if_block3) {
					group_outros();

					transition_out(if_blocks[previous_block_index], 1, 1, () => {
						if_blocks[previous_block_index] = null;
					});

					check_outros();
				}

				if (~current_block_type_index) {
					if_block3 = if_blocks[current_block_type_index];

					if (!if_block3) {
						if_block3 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
						if_block3.c();
					} else {
						
					}

					transition_in(if_block3, 1);
					if_block3.m(div, t3);
				} else {
					if_block3 = null;
				}
			}

			if (/*status*/ ctx[13].formEnabled === false) {
				if (if_block4) {
					if_block4.p(ctx, dirty);

					if (dirty[0] & /*status*/ 8192) {
						transition_in(if_block4, 1);
					}
				} else {
					if_block4 = create_if_block_17(ctx);
					if_block4.c();
					transition_in(if_block4, 1);
					if_block4.m(div, t4);
				}
			} else if (if_block4) {
				group_outros();

				transition_out(if_block4, 1, 1, () => {
					if_block4 = null;
				});

				check_outros();
			}

			if (dirty[0] & /*startTime, time, status*/ 8195) show_if = /*startTime*/ ctx[1] >= /*time*/ ctx[0].getTime() || /*status*/ ctx[13].formEnabled === true;

			if (show_if) {
				if (if_block5) {
					if_block5.p(ctx, dirty);

					if (dirty[0] & /*startTime, time, status*/ 8195) {
						transition_in(if_block5, 1);
					}
				} else {
					if_block5 = create_if_block_16(ctx);
					if_block5.c();
					transition_in(if_block5, 1);
					if_block5.m(div, t5);
				}
			} else if (if_block5) {
				group_outros();

				transition_out(if_block5, 1, 1, () => {
					if_block5 = null;
				});

				check_outros();
			}

			if (/*status*/ ctx[13].formEnabled === true) {
				if (if_block6) {
					if_block6.p(ctx, dirty);

					if (dirty[0] & /*status*/ 8192) {
						transition_in(if_block6, 1);
					}
				} else {
					if_block6 = create_if_block_1(ctx);
					if_block6.c();
					transition_in(if_block6, 1);
					if_block6.m(div, null);
				}
			} else if (if_block6) {
				group_outros();

				transition_out(if_block6, 1, 1, () => {
					if_block6 = null;
				});

				check_outros();
			}

			if (/*status*/ ctx[13].formEnabled !== true && /*status*/ ctx[13].formEnabled !== false) {
				if (if_block7) {
					if (dirty[0] & /*status*/ 8192) {
						transition_in(if_block7, 1);
					}
				} else {
					if_block7 = create_if_block(ctx);
					if_block7.c();
					transition_in(if_block7, 1);
					if_block7.m(if_block7_anchor.parentNode, if_block7_anchor);
				}
			} else if (if_block7) {
				group_outros();

				transition_out(if_block7, 1, 1, () => {
					if_block7 = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);
			transition_in(if_block2);
			transition_in(if_block3);
			transition_in(if_block4);
			transition_in(if_block5);
			transition_in(if_block6);
			transition_in(if_block7);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			transition_out(if_block2);
			transition_out(if_block3);
			transition_out(if_block4);
			transition_out(if_block5);
			transition_out(if_block6);
			transition_out(if_block7);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();

			if (~current_block_type_index) {
				if_blocks[current_block_type_index].d();
			}

			if (if_block4) if_block4.d();
			if (if_block5) if_block5.d();
			if (if_block6) if_block6.d();
			if (detaching) detach(t6);
			if (if_block7) if_block7.d(detaching);
			if (detaching) detach(if_block7_anchor);
		}
	};
}

const func = trip => trip.isChecked;
const func_1 = trip => trip.isChecked && trip.isSubstitute;
const func_2 = trip => trip.isChecked && trip.isSubstitute;
const func_3 = trip => trip.isChecked && trip.isSubstitute;
const func_4 = trip => trip.isChecked && trip.isSubstitute;
const func_5 = trip => trip.isChecked;
const func_6 = trip => trip.isChecked;
const func_7 = trip => trip.isChecked;

function instance($$self, $$props, $$invalidate) {
	let timeMillis;
	const client = new Client();
	client.setEndpoint('https://api.hosts.sk/v1').setProject('62bf1834934dc5cadae7');
	const databases = new Databases(client);
	const account = new Account(client);
	const emailParams = {};
	let time = new Date();
	let startTime, endTime, startTimeFinal, endTimeFinal;
	let capacity;
	let checkRequiredFields = false;
	let allergies, drugs, food, addInfo;
	let currentStep = 0;
	const steps = ['block', 'hidden', 'hidden'];

	const previousStep = event => {
		if (window.scrollY === 0) {
			$$invalidate(12, steps[currentStep] = 'hidden', steps);
			$$invalidate(12, steps[currentStep - 1] = 'block', steps);
			$$invalidate(11, currentStep--, currentStep);
		} else {
			animateScroll.scrollToTop({
				onDone: () => {
					$$invalidate(12, steps[currentStep] = 'hidden', steps);
					$$invalidate(12, steps[currentStep - 1] = 'block', steps);
					$$invalidate(11, currentStep--, currentStep);
				}
			});
		}
	};

	const nextStep = event => {
		if (window.scrollY === 0) {
			$$invalidate(12, steps[currentStep] = 'hidden', steps);
			$$invalidate(12, steps[currentStep + 1] = 'block', steps);
			$$invalidate(11, currentStep++, currentStep);
		} else {
			animateScroll.scrollToTop({
				onDone: () => {
					$$invalidate(12, steps[currentStep] = 'hidden', steps);
					$$invalidate(12, steps[currentStep + 1] = 'block', steps);
					$$invalidate(11, currentStep++, currentStep);
				}
			});
		}
	};

	const status = {
		isTripsLoaded: false,
		isFormSending: false,
		isSuccessfullySent: false,
		isCapacityReached: false,
		isServerError: false,
		isError: false
	};

	const data = {};

	const trips = [
		{
			name: 'Lendak',
			date: '05. 08. 2024', // There must be a space after the dot and 0 present for correct date parsing
			id: 'lendak',
			capacity: undefined,
			responsiblePer: {
				name: 'Zuzana Serafínová',
				mobile: '0905 453 678',
				mail: 'zserafinova@gmail.com'
			},
			isChecked: false,
			isSubstitute: false,
			isDisabled: false
		},
		{
			name: 'Strážky',
			date: '12. 08. 2024',
			id: 'spisska-bela-strazky',
			capacity: undefined,
			responsiblePer: {
				name: 'p. kaplán Lukáš',
				mobile: '0950 465 418',
				mail: 'filicky.lukas2@gmail.com'
			},
			isChecked: false,
			isSubstitute: false,
			isDisabled: false
		}
	];

	// PDF setup
	const exportedPDFs = [];

	pdfMake.fonts = {
		SourceSerifPro: {
			normal: 'https://cdn.hosts.sk/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf',
			bold: 'https://cdn.hosts.sk/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf'
		}
	};

	const createPDF = async (tripId, tripName, pdfProps) => {
		return new Promise(resolve => {
				const pdf = pdfMake.createPdf(pdfProps);

				pdf.getBlob(blob => {
					pdf.getBase64(data => {
						exportedPDFs.push({
							id: tripId,
							name: tripName,
							url: URL.createObjectURL(blob),
							base64: data
						});

						resolve();
					});
				});
			});
	};

	// END PDF setup
	onMount(async () => {
		$$invalidate(13, status.isTripsLoaded = false, status);

		setInterval(
			() => {
				$$invalidate(0, time = new Date());
			},
			1000
		);

		// Check if form can be available
		try {
			const setupData = await databases.getDocument('62bf186d1000fc187d87', '62bf196020497982d1f5', 'baseSetup');
			$$invalidate(1, startTime = new Date(setupData.startTime));
			$$invalidate(2, endTime = new Date(setupData.endTime));
			const substituteCapacity = setupData.substituteCapacity;
			const deadlineForTrips = setupData.deadlineForTrips || 0;
			let minCapacity, maxCapacity;

			setupData.tripsCapacity.forEach(trip => {
				if (!minCapacity && !maxCapacity) {
					minCapacity = trip;
					maxCapacity = trip;
				}

				if (minCapacity > trip) minCapacity = trip; else if (maxCapacity < trip) maxCapacity = trip;
			});

			$$invalidate(5, capacity = minCapacity !== maxCapacity
			? `${minCapacity} - ${maxCapacity}`
			: `${maxCapacity}`);

			for (let i = 0; i < setupData.remainTripsCapacity?.length; i++) {
				$$invalidate(15, trips[i].capacity = setupData.remainTripsCapacity[i], trips);

				if (trips[i].capacity <= 0 && trips[i].capacity > -Math.abs(substituteCapacity)) $$invalidate(15, trips[i].isSubstitute = true, trips); else if (trips[i].capacity <= -Math.abs(substituteCapacity)) {
					$$invalidate(15, trips[i].isSubstitute = false, trips);
					$$invalidate(15, trips[i].isDisabled = true, trips);
				}
			}

			trips.forEach(trip => {
				const dateArr = trip.date?.split('. ');
				let tripDate = new Date(dateArr.reverse().join('-'));
				const nowDate = new Date();
				tripDate.setDate(tripDate.getDate() - deadlineForTrips); // Set date to X days before trip because of deadline
				if (tripDate <= nowDate) trip.isDisabled = true;
			});

			$$invalidate(13, status.isTripsLoaded = true, status);
			$$invalidate(13, status.isError = false, status);
		} catch(e) {
			console.error(e);
			$$invalidate(13, status.isError = true, status);
			$$invalidate(13, status.isServerError = true, status);
			return;
		}
	});

	const updateSession = async function () {
		try {
			const user = await account.getSession('current');
			const timestampNow = +String(new Date().getTime() / 1000).split('.')[0];

			// Refresh access token if user session already expired
			if (user.expire <= timestampNow) {
				try {
					await account.updateSession('current');
				} catch(e) {
					console.error(e);
					return false;
				}
			}

			return true;
		} catch(e) {
			try {
				// Create new anonymous session if no session was already created
				await account.createAnonymousSession();

				return true;
			} catch(err) {
				console.error(err);
				return false;
			}
		}
	};

	const saveData = async () => {
		$$invalidate(13, status.isFormSending = true, status);
		$$invalidate(13, status.isSuccessfullySent = false, status);
		$$invalidate(13, status.isServerError = false, status);
		$$invalidate(13, status.isCapacityReached = false, status);
		$$invalidate(13, status.isError = false, status);
		if (!data.allergies) delete data.allergies;
		if (!data.drugs) delete data.drugs;
		if (!data.food) delete data.food;
		if (!data.addInfo) delete data.addInfo;
		const filterTrips = trips.filter(trip => trip.isChecked);
		$$invalidate(16, exportedPDFs.length = 0, exportedPDFs);
		delete status.isEmailSent;
		emailParams.email = data.parentEmail;
		emailParams.parent_name = data.parentName;
		emailParams.child_name = data.childName;
		emailParams.warning = '';

		try {
			await updateSession();
			const setupData = await databases.getDocument('62bf186d1000fc187d87', '62bf196020497982d1f5', 'baseSetup');
			const substituteCapacity = setupData.substituteCapacity;

			// Check for an available capacity
			for (let trip = 0; trip < filterTrips.length; trip++) {
				const tripIndex = trips.findIndex(tripLocal => tripLocal.id === filterTrips[trip].id);
				if (tripIndex === -1) throw new Error('find-trip-err');

				if (setupData.remainTripsCapacity[tripIndex] <= 0 && setupData.remainTripsCapacity[tripIndex] > -Math.abs(substituteCapacity)) filterTrips[trip].isSubstitute = true; else if (setupData.remainTripsCapacity[tripIndex] <= -Math.abs(substituteCapacity)) {
					filterTrips[trip].isSubstitute = false;
					filterTrips[trip].isDisabled = true;
					throw new Error('max-capacity-reached');
				}

				setupData.remainTripsCapacity[tripIndex]--;
			}

			for (let trip = 0; trip < filterTrips.length; trip++) {
				await createPDF(filterTrips[trip].id, `Informovaný súhlas (${data.childName}, ${filterTrips[trip].name})`, {
					pageSize: 'A5',
					pageOrientation: 'landscape',
					pageMargins: [40, 50, 40, 50],
					content: [
						{
							text: 'INFORMOVANÝ SÚHLAS RODIČA (ZÁKONNÉHO ZÁSTUPCU)',
							style: 'header'
						},
						{
							text: [
								'Meno a priezvisko dieťaťa: ',
								{ text: `${data.childName}`, bold: true }
							],
							margin: [0, 0, 0, 5]
						},
						{
							text: [
								'Dátum narodenia dieťaťa: ',
								{
									text: '...........................................',
									bold: true
								}
							],
							margin: [0, 0, 0, 5]
						},
						{
							text: [
								'Meno a priezvisko zákonného zástupcu: ',
								{ text: `${data.parentName}`, bold: true }
							],
							margin: [0, 0, 0, 5]
						},
						{
							text: [
								'Telefonický kontakt na zákonného zástupcu: ',
								{ text: `${data.parentNum}`, bold: true }
							],
							margin: [0, 0, 0, 10]
						},
						{
							text: [
								'Svojím podpisom potvrdzujem, že som bol informovaný o organizovaní farského výletu v ',
								{
									text: `${filterTrips[trip].name}`,
									bold: true
								},
								' a súhlasím, aby sa moje dieťa ',
								{ text: `${data.childName} `, bold: true },
								`tohto výletu zúčastnil(a). Jeho zdravotný stav ${data.allergies || data.drugs || data.food || data.addInfo
								? 'vyžaduje obmedzenia:'
								: 'nevyžaduje žiadne obmedzenia.'}`
							],
							margin: [0, 0, 0, 10]
						},
						{
							text: [
								`${data.allergies ? 'Dieťa trpí alergiou na: ' : ''}`,
								{
									text: `${data.allergies ? data.allergies : ''}`,
									bold: true
								}
							],
							margin: [0, 0, 0, 3]
						},
						{
							text: [
								`${data.drugs ? 'Užíva lieky: ' : ''}`,
								{
									text: `${data.drugs ? data.drugs : ''}`,
									bold: true
								}
							],
							margin: [0, 0, 0, 3]
						},
						{
							text: [
								`${data.food
								? 'Výnimky v stravovaní, špeciálna strava: '
								: ''}`,
								{
									text: `${data.food ? data.food : ''}`,
									bold: true
								}
							],
							margin: [0, 0, 0, 3]
						},
						{
							text: [
								`${data.addInfo
								? 'Zvláštnosti dieťaťa resp. upozornenie pre zdravotníka: '
								: ''}`,
								{
									text: `${data.addInfo ? data.addInfo : ''}`,
									bold: true
								}
							],
							margin: [0, 0, 0, 3]
						},
						{
							columns: [
								{
									margin: [0, 30, 0, 0],
									width: '50%',
									text: `V Kežmarku dňa ${filterTrips[trip].date}`
								},
								{
									margin: [0, 30, 0, 0],
									width: '50%',
									text: `................................................................`,
									alignment: 'right'
								}
							]
						},
						{
							margin: [0, 5, 0, 0],
							text: `Podpis zákonného zástupcu (${data.parentName})`,
							alignment: 'right'
						}
					],
					defaultStyle: {
						fontSize: 9,
						font: 'SourceSerifPro',
						alignment: 'justify'
					},
					styles: {
						header: {
							margin: [0, 0, 0, 30],
							fontSize: 9,
							bold: true,
							decoration: 'underline'
						}
					}
				});

				$$invalidate(14, data.selectedTrip = filterTrips[trip].id, data);
				$$invalidate(14, data.isSubstitute = filterTrips[trip].isSubstitute, data);
				emailParams.trip = filterTrips[trip].name;
				emailParams.trip_date = filterTrips[trip].date;
				emailParams.responsiblePer = filterTrips[trip].responsiblePer;
				if (filterTrips[trip].isSubstitute) emailParams.warning = `⚠️ Na výlet ${filterTrips[trip].name} ste prihlásili dieťa ako <b>náhradníka</b>. V prípade, že sa uvoľní miesto, budeme Vás kontaktovať! ⚠️`; else emailParams.warning = `Na výlet ${filterTrips[trip].name} ste prihlásili dieťa ako <b>riadneho účastníka</b>. V prípade, ak by sa z Vašej strany niečo zmenilo a dieťa by sa výletu nemohlo zúčastniť, prosíme, kontaktujte nás.`;
				emailParams.attachment = exportedPDFs.filter(pdf => pdf.id === filterTrips[trip].id)[0].base64;

				try {
					// Save an application form
					const document = await databases.createDocument('62bf186d1000fc187d87', '62bf18d774a7d11906d8', ID.unique(), { ...data, childAge: Number(data.childAge) });

					// Decrease available seats
					await databases.updateDocument('62bf186d1000fc187d87', '62bf196020497982d1f5', 'baseSetup', {
						remainTripsCapacity: setupData.remainTripsCapacity
					});

					// SEND EMAIL
					try {
						await axios.post('/.netlify/functions/sendMail', { id: document.$id, ...emailParams });
						delete emailParams.trip;
						delete emailParams.warning;
						delete emailParams.attachment;
						delete emailParams.responsiblePer;
					} catch(e) {
						console.error(e);

						animateScroll.scrollToTop({
							onDone: () => {
								$$invalidate(13, status.isEmailSent = false, status);
								$$invalidate(13, status.isSuccessfullySent = true, status);
								$$invalidate(13, status.isError = true, status);
							}
						});
					}
				} catch(e) {
					console.error(e);

					animateScroll.scrollToTop({
						onDone: () => {
							$$invalidate(13, status.isFormSending = false, status);
							$$invalidate(13, status.isError = true, status);
							$$invalidate(13, status.isServerError = true, status);
						}
					});
				}
			}

			animateScroll.scrollToTop({
				onDone: () => {
					$$invalidate(13, status.isFormSending = false, status);

					if (!status.isError) {
						$$invalidate(13, status.isSuccessfullySent = true, status);
						$$invalidate(13, status.isEmailSent = true, status);
					}

					// Reset all values in form to initial values after form successfully sent
					$$invalidate(7, allergies = $$invalidate(8, drugs = $$invalidate(9, food = $$invalidate(10, addInfo = ''))));

					$$invalidate(14, data.childName = '', data);
					$$invalidate(14, data.childAge = '', data);
					$$invalidate(14, data.parentName = '', data);
					$$invalidate(14, data.parentNum = '', data);
					$$invalidate(14, data.parentEmail = '', data);
					$$invalidate(14, data.selectedTrip = '', data);
					$$invalidate(14, data.allergies = '', data);
					$$invalidate(14, data.drugs = '', data);
					$$invalidate(14, data.food = '', data);
					$$invalidate(14, data.addInfo = '', data);
					$$invalidate(14, data.isSubstitute = false, data);
					$$invalidate(14, data.consent = false, data);

					// Reset checked checkboxes after form successfully sent
					trips.forEach(trip => trip.isChecked = false);
				}
			});
		} catch(err) {
			console.error(err);

			animateScroll.scrollToTop({
				onDone: () => {
					$$invalidate(13, status.isFormSending = false, status);
					$$invalidate(13, status.isServerError = err.message !== 'max-capacity-reached', status);
					$$invalidate(13, status.isError = true, status);
					$$invalidate(13, status.isCapacityReached = err.message === 'max-capacity-reached', status);
				}
			});
		}
	};

	afterUpdate(() => {
		if (trips.some(trip => trip.isChecked) && data.childName && data.childAge >= 6 && data.childAge <= 13 && data.parentName && data.parentNum && data.parentEmail && data.consent) $$invalidate(6, checkRequiredFields = true); else $$invalidate(6, checkRequiredFields = false);

		if (startTime <= timeMillis && endTime >= timeMillis) $$invalidate(13, status.formEnabled = true, status); else if (!startTime || !endTime) delete status.formEnabled; else {
			$$invalidate(13, status.formEnabled = false, status);
			$$invalidate(3, startTimeFinal = new Date(startTime));
			$$invalidate(4, endTimeFinal = new Date(endTime));
		}
	});

	function formfield0_value_binding(value) {
		if ($$self.$$.not_equal(data.childName, value)) {
			data.childName = value;
			$$invalidate(14, data);
		}
	}

	function formfield1_value_binding(value) {
		if ($$self.$$.not_equal(data.childAge, value)) {
			data.childAge = value;
			$$invalidate(14, data);
		}
	}

	function input_change_handler(each_value_1, trip_index_1) {
		each_value_1[trip_index_1].isChecked = this.checked;
		$$invalidate(15, trips);
	}

	function formfield2_group_binding(value) {
		allergies = value;
		$$invalidate(7, allergies);
	}

	function formfield3_group_binding(value) {
		allergies = value;
		$$invalidate(7, allergies);
	}

	function formfield_value_binding(value) {
		if ($$self.$$.not_equal(data.allergies, value)) {
			data.allergies = value;
			$$invalidate(14, data);
		}
	}

	function formfield4_group_binding(value) {
		drugs = value;
		$$invalidate(8, drugs);
	}

	function formfield5_group_binding(value) {
		drugs = value;
		$$invalidate(8, drugs);
	}

	function formfield_value_binding_1(value) {
		if ($$self.$$.not_equal(data.drugs, value)) {
			data.drugs = value;
			$$invalidate(14, data);
		}
	}

	function formfield6_group_binding(value) {
		food = value;
		$$invalidate(9, food);
	}

	function formfield7_group_binding(value) {
		food = value;
		$$invalidate(9, food);
	}

	function formfield_value_binding_2(value) {
		if ($$self.$$.not_equal(data.food, value)) {
			data.food = value;
			$$invalidate(14, data);
		}
	}

	function formfield8_group_binding(value) {
		addInfo = value;
		$$invalidate(10, addInfo);
	}

	function formfield9_group_binding(value) {
		addInfo = value;
		$$invalidate(10, addInfo);
	}

	function formfield_value_binding_3(value) {
		if ($$self.$$.not_equal(data.addInfo, value)) {
			data.addInfo = value;
			$$invalidate(14, data);
		}
	}

	function formfield0_value_binding_1(value) {
		if ($$self.$$.not_equal(data.parentName, value)) {
			data.parentName = value;
			$$invalidate(14, data);
		}
	}

	function formfield1_value_binding_1(value) {
		if ($$self.$$.not_equal(data.parentNum, value)) {
			data.parentNum = value;
			$$invalidate(14, data);
		}
	}

	function formfield2_value_binding(value) {
		if ($$self.$$.not_equal(data.parentEmail, value)) {
			data.parentEmail = value;
			$$invalidate(14, data);
		}
	}

	function formfield3_checked_binding(value) {
		if ($$self.$$.not_equal(data.consent, value)) {
			data.consent = value;
			$$invalidate(14, data);
		}
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*time*/ 1) {
			$: timeMillis = time.getTime();
		}
	};

	return [
		time,
		startTime,
		endTime,
		startTimeFinal,
		endTimeFinal,
		capacity,
		checkRequiredFields,
		allergies,
		drugs,
		food,
		addInfo,
		currentStep,
		steps,
		status,
		data,
		trips,
		exportedPDFs,
		previousStep,
		nextStep,
		saveData,
		formfield0_value_binding,
		formfield1_value_binding,
		input_change_handler,
		formfield2_group_binding,
		formfield3_group_binding,
		formfield_value_binding,
		formfield4_group_binding,
		formfield5_group_binding,
		formfield_value_binding_1,
		formfield6_group_binding,
		formfield7_group_binding,
		formfield_value_binding_2,
		formfield8_group_binding,
		formfield9_group_binding,
		formfield_value_binding_3,
		formfield0_value_binding_1,
		formfield1_value_binding_1,
		formfield2_value_binding,
		formfield3_checked_binding
	];
}

class Form extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {}, null, [-1, -1]);
	}
}

export default Form;