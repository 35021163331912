import './Alert.svelte.css';
/* src/components/Alert.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	create_slot,
	detach,
	element,
	empty,
	get_all_dirty_from_scope,
	get_slot_changes,
	group_outros,
	init,
	insert,
	safe_not_equal,
	space,
	toggle_class,
	transition_in,
	transition_out,
	update_slot_base
} from "../../_snowpack/pkg/svelte/internal.js";

function create_if_block_3(ctx) {
	let section;
	let aside0;
	let t;
	let aside1;
	let section_class_value;
	let current;
	const default_slot_template = /*#slots*/ ctx[4].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[3], null);

	return {
		c() {
			section = element("section");
			aside0 = element("aside");
			aside0.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-3 inline-block text-blue-400" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>`;
			t = space();
			aside1 = element("aside");
			if (default_slot) default_slot.c();
			toggle_class(aside0, "inline-flex", /*oneline*/ ctx[1]);

			attr(section, "class", section_class_value = "text-blue-500 dark:text-blue-50 " + (/*oneline*/ ctx[1]
			? 'font-semibold items-center'
			: 'items-start') + " bg-blue-50 dark:bg-blue-900 " + /*customClass*/ ctx[2] + " svelte-ld5y75");
		},
		m(target, anchor) {
			insert(target, section, anchor);
			append(section, aside0);
			append(section, t);
			append(section, aside1);

			if (default_slot) {
				default_slot.m(aside1, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (!current || dirty & /*oneline*/ 2) {
				toggle_class(aside0, "inline-flex", /*oneline*/ ctx[1]);
			}

			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 8)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[3],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[3])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[3], dirty, null),
						null
					);
				}
			}

			if (!current || dirty & /*oneline, customClass*/ 6 && section_class_value !== (section_class_value = "text-blue-500 dark:text-blue-50 " + (/*oneline*/ ctx[1]
			? 'font-semibold items-center'
			: 'items-start') + " bg-blue-50 dark:bg-blue-900 " + /*customClass*/ ctx[2] + " svelte-ld5y75")) {
				attr(section, "class", section_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(section);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

// (56:27) 
function create_if_block_2(ctx) {
	let section;
	let aside0;
	let t;
	let aside1;
	let section_class_value;
	let current;
	const default_slot_template = /*#slots*/ ctx[4].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[3], null);

	return {
		c() {
			section = element("section");
			aside0 = element("aside");
			aside0.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-3 inline-block text-red-400" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"></path></svg>`;
			t = space();
			aside1 = element("aside");
			if (default_slot) default_slot.c();
			toggle_class(aside0, "inline-flex", /*oneline*/ ctx[1]);

			attr(section, "class", section_class_value = "text-red-900 dark:text-red-50 " + (/*oneline*/ ctx[1]
			? 'font-semibold items-center'
			: 'items-start') + " bg-red-50 dark:bg-red-900 " + /*customClass*/ ctx[2] + " svelte-ld5y75");
		},
		m(target, anchor) {
			insert(target, section, anchor);
			append(section, aside0);
			append(section, t);
			append(section, aside1);

			if (default_slot) {
				default_slot.m(aside1, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (!current || dirty & /*oneline*/ 2) {
				toggle_class(aside0, "inline-flex", /*oneline*/ ctx[1]);
			}

			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 8)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[3],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[3])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[3], dirty, null),
						null
					);
				}
			}

			if (!current || dirty & /*oneline, customClass*/ 6 && section_class_value !== (section_class_value = "text-red-900 dark:text-red-50 " + (/*oneline*/ ctx[1]
			? 'font-semibold items-center'
			: 'items-start') + " bg-red-50 dark:bg-red-900 " + /*customClass*/ ctx[2] + " svelte-ld5y75")) {
				attr(section, "class", section_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(section);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

// (31:29) 
function create_if_block_1(ctx) {
	let section;
	let aside0;
	let t;
	let aside1;
	let section_class_value;
	let current;
	const default_slot_template = /*#slots*/ ctx[4].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[3], null);

	return {
		c() {
			section = element("section");
			aside0 = element("aside");
			aside0.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-3 inline-block text-amber-500" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>`;
			t = space();
			aside1 = element("aside");
			if (default_slot) default_slot.c();
			toggle_class(aside0, "inline-flex", /*oneline*/ ctx[1]);

			attr(section, "class", section_class_value = "" + ((/*oneline*/ ctx[1]
			? 'font-semibold items-center'
			: 'items-start') + " bg-amber-50 dark:bg-gray-700 text-amber-900 dark:text-white " + /*customClass*/ ctx[2] + " svelte-ld5y75"));

			attr(section, "role", "alert");
		},
		m(target, anchor) {
			insert(target, section, anchor);
			append(section, aside0);
			append(section, t);
			append(section, aside1);

			if (default_slot) {
				default_slot.m(aside1, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (!current || dirty & /*oneline*/ 2) {
				toggle_class(aside0, "inline-flex", /*oneline*/ ctx[1]);
			}

			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 8)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[3],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[3])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[3], dirty, null),
						null
					);
				}
			}

			if (!current || dirty & /*oneline, customClass*/ 6 && section_class_value !== (section_class_value = "" + ((/*oneline*/ ctx[1]
			? 'font-semibold items-center'
			: 'items-start') + " bg-amber-50 dark:bg-gray-700 text-amber-900 dark:text-white " + /*customClass*/ ctx[2] + " svelte-ld5y75"))) {
				attr(section, "class", section_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(section);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

// (7:0) {#if type === 'success'}
function create_if_block(ctx) {
	let section;
	let aside0;
	let t;
	let aside1;
	let section_class_value;
	let current;
	const default_slot_template = /*#slots*/ ctx[4].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[3], null);

	return {
		c() {
			section = element("section");
			aside0 = element("aside");
			aside0.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-3 inline-block text-green-400" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>`;
			t = space();
			aside1 = element("aside");
			if (default_slot) default_slot.c();
			toggle_class(aside0, "inline-flex", /*oneline*/ ctx[1]);

			attr(section, "class", section_class_value = "text-green-900 dark:text-green-50 " + (/*oneline*/ ctx[1]
			? 'font-semibold items-center'
			: 'items-start') + " bg-green-50 dark:bg-green-900 " + /*customClass*/ ctx[2] + " svelte-ld5y75");
		},
		m(target, anchor) {
			insert(target, section, anchor);
			append(section, aside0);
			append(section, t);
			append(section, aside1);

			if (default_slot) {
				default_slot.m(aside1, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (!current || dirty & /*oneline*/ 2) {
				toggle_class(aside0, "inline-flex", /*oneline*/ ctx[1]);
			}

			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 8)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[3],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[3])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[3], dirty, null),
						null
					);
				}
			}

			if (!current || dirty & /*oneline, customClass*/ 6 && section_class_value !== (section_class_value = "text-green-900 dark:text-green-50 " + (/*oneline*/ ctx[1]
			? 'font-semibold items-center'
			: 'items-start') + " bg-green-50 dark:bg-green-900 " + /*customClass*/ ctx[2] + " svelte-ld5y75")) {
				attr(section, "class", section_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(section);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function create_fragment(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block, create_if_block_1, create_if_block_2, create_if_block_3];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*type*/ ctx[0] === 'success') return 0;
		if (/*type*/ ctx[0] === 'warning') return 1;
		if (/*type*/ ctx[0] === 'error') return 2;
		if (/*type*/ ctx[0] === 'info') return 3;
		return -1;
	}

	if (~(current_block_type_index = select_block_type(ctx, -1))) {
		if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	}

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (~current_block_type_index) {
				if_blocks[current_block_type_index].m(target, anchor);
			}

			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if (~current_block_type_index) {
					if_blocks[current_block_type_index].p(ctx, dirty);
				}
			} else {
				if (if_block) {
					group_outros();

					transition_out(if_blocks[previous_block_index], 1, 1, () => {
						if_blocks[previous_block_index] = null;
					});

					check_outros();
				}

				if (~current_block_type_index) {
					if_block = if_blocks[current_block_type_index];

					if (!if_block) {
						if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
						if_block.c();
					} else {
						if_block.p(ctx, dirty);
					}

					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				} else {
					if_block = null;
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (~current_block_type_index) {
				if_blocks[current_block_type_index].d(detaching);
			}

			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { type = 'success' } = $$props;
	let { oneline = false } = $$props;
	let { customClass } = $$props;

	$$self.$$set = $$props => {
		if ('type' in $$props) $$invalidate(0, type = $$props.type);
		if ('oneline' in $$props) $$invalidate(1, oneline = $$props.oneline);
		if ('customClass' in $$props) $$invalidate(2, customClass = $$props.customClass);
		if ('$$scope' in $$props) $$invalidate(3, $$scope = $$props.$$scope);
	};

	return [type, oneline, customClass, $$scope, slots];
}

class Alert extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { type: 0, oneline: 1, customClass: 2 });
	}
}

export default Alert;