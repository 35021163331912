import './App.svelte.css';
/* src/App.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import './App.css';
import Erb from './components/Erb.svelte.js';
import Form from './components/Form.svelte.js';

function create_fragment(ctx) {
	let main;
	let div0;
	let aside;
	let erb;
	let t0;
	let section;
	let h1;
	let t2;
	let form;
	let t3;
	let footer;
	let current;

	erb = new Erb({
			props: {
				customClass: "w-24 m-10 mx-auto xl:sticky xl:top-16"
			}
		});

	form = new Form({});

	return {
		c() {
			main = element("main");
			div0 = element("div");
			aside = element("aside");
			create_component(erb.$$.fragment);
			t0 = space();
			section = element("section");
			h1 = element("h1");
			h1.textContent = "Prihláška na letné farské výlety 2024";
			t2 = space();
			create_component(form.$$.fragment);
			t3 = space();
			footer = element("footer");

			footer.innerHTML = `<div class="container flex flex-col items-center"><p class="mb-3">© 2024
      <a href="https://www.fara-kezmarok.sk">www.fara-kezmarok.sk</a>.</p> 
    <a href="mailto:damian@hosts.sk" aria-label="Damian Dlugosch – Web Developer" class="text-secondary dark:text-white branding transition-all duration-500 ease-in-out svelte-117gao9"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 360 360"><path d="M360,180a35.92,35.92,0,0,0-10.54-25.46l-144-144a36,36,0,0,0-50.92,50.92l72,72h0L273.09,180l-46.54,46.54h0l-72,72h0L153,300.09l.05.05A36,36,0,0,0,203.86,351l0,.05,1.54-1.54h0l72-72h0L347.91,207l-.05-.05A35.92,35.92,0,0,0,360,180Z" fill="currentColor" class="svelte-117gao9"></path><path d="M211,198.39l.09-.17c.28-.46.54-.93.79-1.41l.18-.34c.22-.42.43-.85.63-1.28l.21-.46q.27-.6.51-1.2l.22-.55c.15-.39.29-.77.43-1.16.07-.21.14-.41.2-.62.13-.37.24-.75.35-1.13.07-.22.13-.45.19-.67.1-.37.19-.75.28-1.13s.11-.46.17-.7.15-.75.22-1.13l.12-.72c.07-.39.12-.79.17-1.18,0-.24.06-.47.08-.7.05-.43.08-.87.11-1.3,0-.2,0-.41,0-.61,0-.64.05-1.28.05-1.93a34.48,34.48,0,0,0-.19-3.68,35.9,35.9,0,0,0-10.35-21.78l-144-144A35.9,35.9,0,0,0,39.68.19,34.48,34.48,0,0,0,36,0,36,36,0,0,0,10.54,61.46l72,72h0L129.09,180,82.55,226.54h0l-72,72h0L9,300.09l.05.05a35.92,35.92,0,0,0-5.24,7.75l-.12.22c-.18.37-.35.74-.52,1.11l-.19.43-.42,1-.22.55c-.12.32-.24.65-.35,1s-.15.43-.22.65-.19.62-.29.93-.14.48-.2.73-.16.59-.23.88-.13.54-.19.81-.13.57-.18.85-.11.59-.16.89-.09.54-.13.81-.09.65-.13,1l-.09.74c0,.38-.06.76-.08,1.15,0,.2,0,.4,0,.61,0,.59,0,1.18,0,1.78a36,36,0,0,0,36,36c.6,0,1.19,0,1.78-.05l.61,0,1.15-.08.74-.09,1-.13.81-.13.89-.16.85-.18.81-.19.88-.23.73-.2.93-.29.65-.22,1-.35.55-.22,1-.42.43-.19,1.11-.52.22-.12A35.92,35.92,0,0,0,59.86,351l0,.05,1.54-1.54h0l72-72h0L203.91,207l0-.05A36.27,36.27,0,0,0,211,198.39Z" fill="currentColor" class="svelte-117gao9"></path><path d="M71.81,176.32a35.9,35.9,0,0,0-10.35-21.78L0,93.09V324a35.83,35.83,0,0,1,9.05-23.86L9,300.09l1.54-1.54h0L72,237.09V180A34.48,34.48,0,0,0,71.81,176.32Z" fill="currentColor" class="svelte-117gao9"></path></svg></a></div>`;

			attr(aside, "class", "xl:fixed xl:top-6 xl:left-28");
			attr(h1, "class", "text-center mb-10 xl:pb-0 xl:my-24 text-primary dark:text-white");
			attr(section, "class", "");
			attr(div0, "class", "container");
			attr(main, "class", "relative");
			attr(footer, "class", "text-center mb-10 mt-10 xl:mt-24");
		},
		m(target, anchor) {
			insert(target, main, anchor);
			append(main, div0);
			append(div0, aside);
			mount_component(erb, aside, null);
			append(div0, t0);
			append(div0, section);
			append(section, h1);
			append(section, t2);
			mount_component(form, section, null);
			insert(target, t3, anchor);
			insert(target, footer, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(erb.$$.fragment, local);
			transition_in(form.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(erb.$$.fragment, local);
			transition_out(form.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(main);
			destroy_component(erb);
			destroy_component(form);
			if (detaching) detach(t3);
			if (detaching) detach(footer);
		}
	};
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default App;