import './FormField.svelte.css';
/* src/components/FormField.svelte generated by Svelte v3.59.2 */
import {
	HtmlTag,
	SvelteComponent,
	action_destroyer,
	append,
	attr,
	check_outros,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	init_binding_group,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	set_input_value,
	space,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import MaskInput from '../../_snowpack/pkg/svelte-input-mask/MaskInput.svelte.js';

function create_if_block_7(ctx) {
	let div2;
	let div0;
	let input;
	let input_class_value;
	let t0;
	let div1;
	let label;
	let html_tag;
	let t1;
	let mounted;
	let dispose;
	let if_block = /*required*/ ctx[8] && create_if_block_8(ctx);

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			input = element("input");
			t0 = space();
			div1 = element("div");
			label = element("label");
			html_tag = new HtmlTag(false);
			t1 = space();
			if (if_block) if_block.c();
			attr(input, "type", "checkbox");
			attr(input, "id", /*id*/ ctx[6]);
			attr(input, "class", input_class_value = "form-checkbox check " + /*customClass*/ ctx[7] + " svelte-1qhsfwv");
			input.required = /*required*/ ctx[8];
			input.__value = /*value*/ ctx[1];
			input.value = input.__value;
			attr(div0, "class", "flex items-end h-5");
			html_tag.a = t1;
			attr(label, "for", /*id*/ ctx[6]);
			attr(label, "class", "label-checkbox svelte-1qhsfwv");
			attr(div1, "class", "ml-3");
			attr(div2, "class", "flex items-start");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div0, input);
			input.checked = /*checked*/ ctx[2];
			append(div2, t0);
			append(div2, div1);
			append(div1, label);
			html_tag.m(/*text*/ ctx[3], label);
			append(label, t1);
			if (if_block) if_block.m(label, null);

			if (!mounted) {
				dispose = listen(input, "change", /*input_change_handler_1*/ ctx[22]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*id*/ 64) {
				attr(input, "id", /*id*/ ctx[6]);
			}

			if (dirty & /*customClass*/ 128 && input_class_value !== (input_class_value = "form-checkbox check " + /*customClass*/ ctx[7] + " svelte-1qhsfwv")) {
				attr(input, "class", input_class_value);
			}

			if (dirty & /*required*/ 256) {
				input.required = /*required*/ ctx[8];
			}

			if (dirty & /*value*/ 2) {
				input.__value = /*value*/ ctx[1];
				input.value = input.__value;
			}

			if (dirty & /*checked*/ 4) {
				input.checked = /*checked*/ ctx[2];
			}

			if (dirty & /*text*/ 8) html_tag.p(/*text*/ ctx[3]);

			if (/*required*/ ctx[8]) {
				if (if_block) {
					
				} else {
					if_block = create_if_block_8(ctx);
					if_block.c();
					if_block.m(label, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*id*/ 64) {
				attr(label, "for", /*id*/ ctx[6]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div2);
			if (if_block) if_block.d();
			mounted = false;
			dispose();
		}
	};
}

// (99:27) 
function create_if_block_6(ctx) {
	let input;
	let input_class_value;
	let value_has_changed = false;
	let t;
	let html_tag;
	let html_anchor;
	let binding_group;
	let mounted;
	let dispose;
	binding_group = init_binding_group(/*$$binding_groups*/ ctx[21][0]);

	return {
		c() {
			input = element("input");
			t = space();
			html_tag = new HtmlTag(false);
			html_anchor = empty();
			attr(input, "name", /*name*/ ctx[5]);
			attr(input, "type", "radio");
			attr(input, "class", input_class_value = "form-radio h-6 w-6 mr-3 check " + /*customClass*/ ctx[7] + " svelte-1qhsfwv");
			input.__value = /*value*/ ctx[1];
			input.value = input.__value;
			html_tag.a = html_anchor;
			binding_group.p(input);
		},
		m(target, anchor) {
			insert(target, input, anchor);
			input.checked = input.__value === /*group*/ ctx[0];
			insert(target, t, anchor);
			html_tag.m(/*text*/ ctx[3], target, anchor);
			insert(target, html_anchor, anchor);

			if (!mounted) {
				dispose = listen(input, "change", /*input_change_handler*/ ctx[20]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*name*/ 32) {
				attr(input, "name", /*name*/ ctx[5]);
			}

			if (dirty & /*customClass*/ 128 && input_class_value !== (input_class_value = "form-radio h-6 w-6 mr-3 check " + /*customClass*/ ctx[7] + " svelte-1qhsfwv")) {
				attr(input, "class", input_class_value);
			}

			if (dirty & /*value*/ 2) {
				input.__value = /*value*/ ctx[1];
				input.value = input.__value;
				value_has_changed = true;
			}

			if (value_has_changed || dirty & /*group*/ 1) {
				input.checked = input.__value === /*group*/ ctx[0];
			}

			if (dirty & /*text*/ 8) html_tag.p(/*text*/ ctx[3]);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(input);
			if (detaching) detach(t);
			if (detaching) detach(html_anchor);
			if (detaching) html_tag.d();
			binding_group.r();
			mounted = false;
			dispose();
		}
	};
}

// (50:0) {#if typeInput.indexOf(type) !== -1 || type === 'textarea' || type === 'tel'}
function create_if_block(ctx) {
	let label;
	let html_tag;
	let t0;
	let t1;
	let show_if = /*typeInput*/ ctx[15].indexOf(/*type*/ ctx[4]) !== -1;
	let t2;
	let t3;
	let t4;
	let if_block4_anchor;
	let current;
	let if_block0 = /*required*/ ctx[8] && create_if_block_5(ctx);
	let if_block1 = show_if && create_if_block_4(ctx);
	let if_block2 = /*type*/ ctx[4] === 'textarea' && create_if_block_3(ctx);
	let if_block3 = /*type*/ ctx[4] === 'tel' && create_if_block_2(ctx);
	let if_block4 = /*description*/ ctx[12] && create_if_block_1(ctx);

	return {
		c() {
			label = element("label");
			html_tag = new HtmlTag(false);
			t0 = space();
			if (if_block0) if_block0.c();
			t1 = space();
			if (if_block1) if_block1.c();
			t2 = space();
			if (if_block2) if_block2.c();
			t3 = space();
			if (if_block3) if_block3.c();
			t4 = space();
			if (if_block4) if_block4.c();
			if_block4_anchor = empty();
			html_tag.a = t0;
			attr(label, "class", "svelte-1qhsfwv");
		},
		m(target, anchor) {
			insert(target, label, anchor);
			html_tag.m(/*text*/ ctx[3], label);
			append(label, t0);
			if (if_block0) if_block0.m(label, null);
			append(label, t1);
			if (if_block1) if_block1.m(label, null);
			append(label, t2);
			if (if_block2) if_block2.m(label, null);
			append(label, t3);
			if (if_block3) if_block3.m(label, null);
			insert(target, t4, anchor);
			if (if_block4) if_block4.m(target, anchor);
			insert(target, if_block4_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (!current || dirty & /*text*/ 8) html_tag.p(/*text*/ ctx[3]);

			if (/*required*/ ctx[8]) {
				if (if_block0) {
					
				} else {
					if_block0 = create_if_block_5(ctx);
					if_block0.c();
					if_block0.m(label, t1);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (dirty & /*type*/ 16) show_if = /*typeInput*/ ctx[15].indexOf(/*type*/ ctx[4]) !== -1;

			if (show_if) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_4(ctx);
					if_block1.c();
					if_block1.m(label, t2);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (/*type*/ ctx[4] === 'textarea') {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block_3(ctx);
					if_block2.c();
					if_block2.m(label, t3);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}

			if (/*type*/ ctx[4] === 'tel') {
				if (if_block3) {
					if_block3.p(ctx, dirty);

					if (dirty & /*type*/ 16) {
						transition_in(if_block3, 1);
					}
				} else {
					if_block3 = create_if_block_2(ctx);
					if_block3.c();
					transition_in(if_block3, 1);
					if_block3.m(label, null);
				}
			} else if (if_block3) {
				group_outros();

				transition_out(if_block3, 1, 1, () => {
					if_block3 = null;
				});

				check_outros();
			}

			if (/*description*/ ctx[12]) {
				if (if_block4) {
					if_block4.p(ctx, dirty);
				} else {
					if_block4 = create_if_block_1(ctx);
					if_block4.c();
					if_block4.m(if_block4_anchor.parentNode, if_block4_anchor);
				}
			} else if (if_block4) {
				if_block4.d(1);
				if_block4 = null;
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block3);
			current = true;
		},
		o(local) {
			transition_out(if_block3);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(label);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			if (if_block3) if_block3.d();
			if (detaching) detach(t4);
			if (if_block4) if_block4.d(detaching);
			if (detaching) detach(if_block4_anchor);
		}
	};
}

// (123:8) {#if required}
function create_if_block_8(ctx) {
	let span;

	return {
		c() {
			span = element("span");
			span.textContent = "*";
			attr(span, "class", "text-primary dark:text-red-500");
		},
		m(target, anchor) {
			insert(target, span, anchor);
		},
		d(detaching) {
			if (detaching) detach(span);
		}
	};
}

// (53:4) {#if required}
function create_if_block_5(ctx) {
	let span;

	return {
		c() {
			span = element("span");
			span.textContent = "*";
			attr(span, "class", "text-primary dark:text-red-500");
		},
		m(target, anchor) {
			insert(target, span, anchor);
		},
		d(detaching) {
			if (detaching) detach(span);
		}
	};
}

// (57:4) {#if typeInput.indexOf(type) !== -1}
function create_if_block_4(ctx) {
	let input;
	let input_class_value;
	let typeAction_action;
	let mounted;
	let dispose;

	return {
		c() {
			input = element("input");
			attr(input, "id", /*id*/ ctx[6]);
			attr(input, "class", input_class_value = "form-input block " + /*customClass*/ ctx[7] + " svelte-1qhsfwv");
			attr(input, "min", /*min*/ ctx[9]);
			attr(input, "max", /*max*/ ctx[10]);
			input.required = /*required*/ ctx[8];
			attr(input, "pattern", /*pattern*/ ctx[13]);
		},
		m(target, anchor) {
			insert(target, input, anchor);
			set_input_value(input, /*value*/ ctx[1]);

			if (!mounted) {
				dispose = [
					listen(input, "input", /*input_input_handler*/ ctx[18]),
					action_destroyer(typeAction_action = /*typeAction*/ ctx[16].call(null, input))
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*id*/ 64) {
				attr(input, "id", /*id*/ ctx[6]);
			}

			if (dirty & /*customClass*/ 128 && input_class_value !== (input_class_value = "form-input block " + /*customClass*/ ctx[7] + " svelte-1qhsfwv")) {
				attr(input, "class", input_class_value);
			}

			if (dirty & /*min*/ 512) {
				attr(input, "min", /*min*/ ctx[9]);
			}

			if (dirty & /*max*/ 1024) {
				attr(input, "max", /*max*/ ctx[10]);
			}

			if (dirty & /*required*/ 256) {
				input.required = /*required*/ ctx[8];
			}

			if (dirty & /*pattern*/ 8192) {
				attr(input, "pattern", /*pattern*/ ctx[13]);
			}

			if (dirty & /*value*/ 2 && input.value !== /*value*/ ctx[1]) {
				set_input_value(input, /*value*/ ctx[1]);
			}
		},
		d(detaching) {
			if (detaching) detach(input);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (70:4) {#if type === 'textarea'}
function create_if_block_3(ctx) {
	let textarea;
	let textarea_class_value;
	let mounted;
	let dispose;

	return {
		c() {
			textarea = element("textarea");
			attr(textarea, "id", /*id*/ ctx[6]);
			attr(textarea, "rows", "5");
			attr(textarea, "placeholder", /*placeholder*/ ctx[11]);
			attr(textarea, "class", textarea_class_value = "form-textarea resize-none block placeholder-gray-500 " + /*customClass*/ ctx[7] + " svelte-1qhsfwv");
		},
		m(target, anchor) {
			insert(target, textarea, anchor);
			set_input_value(textarea, /*value*/ ctx[1]);

			if (!mounted) {
				dispose = listen(textarea, "input", /*textarea_input_handler*/ ctx[19]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*id*/ 64) {
				attr(textarea, "id", /*id*/ ctx[6]);
			}

			if (dirty & /*placeholder*/ 2048) {
				attr(textarea, "placeholder", /*placeholder*/ ctx[11]);
			}

			if (dirty & /*customClass*/ 128 && textarea_class_value !== (textarea_class_value = "form-textarea resize-none block placeholder-gray-500 " + /*customClass*/ ctx[7] + " svelte-1qhsfwv")) {
				attr(textarea, "class", textarea_class_value);
			}

			if (dirty & /*value*/ 2) {
				set_input_value(textarea, /*value*/ ctx[1]);
			}
		},
		d(detaching) {
			if (detaching) detach(textarea);
			mounted = false;
			dispose();
		}
	};
}

// (80:4) {#if type === 'tel'}
function create_if_block_2(ctx) {
	let maskinput;
	let current;

	maskinput = new MaskInput({
			props: {
				type: "tel",
				class: "form-input border-border dark:border-border-dark focus:border-primary focus:ring-0 dark:focus:border-primary-light min-h-12 mt-3 rounded-lg bg-white-pure dark:bg-secondary-light placeholder-gray-400 " + /*customClass*/ ctx[7],
				id: /*id*/ ctx[6],
				mask: /*mask*/ ctx[14],
				placeholder: /*placeholder*/ ctx[11],
				required: /*required*/ ctx[8],
				value: /*value*/ ctx[1],
				maskChar: "_"
			}
		});

	maskinput.$on("change", /*handleChange*/ ctx[17]);

	return {
		c() {
			create_component(maskinput.$$.fragment);
		},
		m(target, anchor) {
			mount_component(maskinput, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const maskinput_changes = {};
			if (dirty & /*customClass*/ 128) maskinput_changes.class = "form-input border-border dark:border-border-dark focus:border-primary focus:ring-0 dark:focus:border-primary-light min-h-12 mt-3 rounded-lg bg-white-pure dark:bg-secondary-light placeholder-gray-400 " + /*customClass*/ ctx[7];
			if (dirty & /*id*/ 64) maskinput_changes.id = /*id*/ ctx[6];
			if (dirty & /*mask*/ 16384) maskinput_changes.mask = /*mask*/ ctx[14];
			if (dirty & /*placeholder*/ 2048) maskinput_changes.placeholder = /*placeholder*/ ctx[11];
			if (dirty & /*required*/ 256) maskinput_changes.required = /*required*/ ctx[8];
			if (dirty & /*value*/ 2) maskinput_changes.value = /*value*/ ctx[1];
			maskinput.$set(maskinput_changes);
		},
		i(local) {
			if (current) return;
			transition_in(maskinput.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(maskinput.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(maskinput, detaching);
		}
	};
}

// (94:2) {#if description}
function create_if_block_1(ctx) {
	let p;

	return {
		c() {
			p = element("p");
			attr(p, "class", "mt-4 text-sm text-gray-500 dark:text-gray-400");
		},
		m(target, anchor) {
			insert(target, p, anchor);
			p.innerHTML = /*description*/ ctx[12];
		},
		p(ctx, dirty) {
			if (dirty & /*description*/ 4096) p.innerHTML = /*description*/ ctx[12];;
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

function create_fragment(ctx) {
	let show_if;
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block, create_if_block_6, create_if_block_7];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (dirty & /*type*/ 16) show_if = null;
		if (show_if == null) show_if = !!(/*typeInput*/ ctx[15].indexOf(/*type*/ ctx[4]) !== -1 || /*type*/ ctx[4] === 'textarea' || /*type*/ ctx[4] === 'tel');
		if (show_if) return 0;
		if (/*type*/ ctx[4] === 'radio') return 1;
		if (/*type*/ ctx[4] === 'checkbox') return 2;
		return -1;
	}

	if (~(current_block_type_index = select_block_type(ctx, -1))) {
		if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	}

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (~current_block_type_index) {
				if_blocks[current_block_type_index].m(target, anchor);
			}

			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if (~current_block_type_index) {
					if_blocks[current_block_type_index].p(ctx, dirty);
				}
			} else {
				if (if_block) {
					group_outros();

					transition_out(if_blocks[previous_block_index], 1, 1, () => {
						if_blocks[previous_block_index] = null;
					});

					check_outros();
				}

				if (~current_block_type_index) {
					if_block = if_blocks[current_block_type_index];

					if (!if_block) {
						if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
						if_block.c();
					} else {
						if_block.p(ctx, dirty);
					}

					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				} else {
					if_block = null;
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (~current_block_type_index) {
				if_blocks[current_block_type_index].d(detaching);
			}

			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { text } = $$props;
	let { type } = $$props;
	let { name } = $$props;
	let { group } = $$props;
	let { value = '' } = $$props;
	let { id } = $$props;
	let { customClass = '' } = $$props;
	let { required = false } = $$props;
	let { min } = $$props;
	let { max } = $$props;
	let { placeholder } = $$props;
	let { description } = $$props;
	let { checked } = $$props;
	let { pattern } = $$props;

	let typeInput = [
		'text',
		'email',
		'url',
		'password',
		'date',
		'datetime-local',
		'month',
		'search',
		'number',
		'time',
		'week'
	];

	const typeAction = node => {
		node.type = type;
	};

	let mask = '0000 000 000';

	const handleChange = ({ detail }) => {
		$$invalidate(1, value = detail.inputState.visibleValue);

		if (detail.inputState.maskedValue.indexOf('5') === 1) {
			$$invalidate(14, mask = '000/000 00 00');
			return;
		}

		$$invalidate(14, mask = '0000 000 000');
	};

	const $$binding_groups = [[]];

	function input_input_handler() {
		value = this.value;
		$$invalidate(1, value);
	}

	function textarea_input_handler() {
		value = this.value;
		$$invalidate(1, value);
	}

	function input_change_handler() {
		group = this.__value;
		$$invalidate(0, group);
	}

	function input_change_handler_1() {
		checked = this.checked;
		$$invalidate(2, checked);
	}

	$$self.$$set = $$props => {
		if ('text' in $$props) $$invalidate(3, text = $$props.text);
		if ('type' in $$props) $$invalidate(4, type = $$props.type);
		if ('name' in $$props) $$invalidate(5, name = $$props.name);
		if ('group' in $$props) $$invalidate(0, group = $$props.group);
		if ('value' in $$props) $$invalidate(1, value = $$props.value);
		if ('id' in $$props) $$invalidate(6, id = $$props.id);
		if ('customClass' in $$props) $$invalidate(7, customClass = $$props.customClass);
		if ('required' in $$props) $$invalidate(8, required = $$props.required);
		if ('min' in $$props) $$invalidate(9, min = $$props.min);
		if ('max' in $$props) $$invalidate(10, max = $$props.max);
		if ('placeholder' in $$props) $$invalidate(11, placeholder = $$props.placeholder);
		if ('description' in $$props) $$invalidate(12, description = $$props.description);
		if ('checked' in $$props) $$invalidate(2, checked = $$props.checked);
		if ('pattern' in $$props) $$invalidate(13, pattern = $$props.pattern);
	};

	return [
		group,
		value,
		checked,
		text,
		type,
		name,
		id,
		customClass,
		required,
		min,
		max,
		placeholder,
		description,
		pattern,
		mask,
		typeInput,
		typeAction,
		handleChange,
		input_input_handler,
		textarea_input_handler,
		input_change_handler,
		$$binding_groups,
		input_change_handler_1
	];
}

class FormField extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			text: 3,
			type: 4,
			name: 5,
			group: 0,
			value: 1,
			id: 6,
			customClass: 7,
			required: 8,
			min: 9,
			max: 10,
			placeholder: 11,
			description: 12,
			checked: 2,
			pattern: 13
		});
	}
}

export default FormField;