/* src/components/Skeleton.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	empty,
	init,
	insert,
	noop,
	safe_not_equal
} from "../../_snowpack/pkg/svelte/internal.js";

function create_if_block(ctx) {
	let div8;

	return {
		c() {
			div8 = element("div");

			div8.innerHTML = `<div class="animate-pulse"><div class="w-full bg-secondary-light h-36 sm:h-28 md:h-32 lg:h-44 xl:h-28 2xl:h-32"></div> 

      <div class="flex-1 space-y-4 p-4"><div class="h-4 bg-secondary-light rounded w-1/2 mx-auto"></div> 
        <div class="space-y-2"><div class="h-4 bg-secondary-light rounded"></div> 
          <div class="h-4 bg-secondary-light rounded"></div> 
          <div class="h-4 bg-secondary-light rounded w-1/3 mx-auto"></div></div></div></div>`;

			attr(div8, "class", "box-border border border-secondary dark:border-white shadow rounded mx-auto w-full mt-5 text-center flex flex-col overflow-hidden");
		},
		m(target, anchor) {
			insert(target, div8, anchor);
		},
		d(detaching) {
			if (detaching) detach(div8);
		}
	};
}

function create_fragment(ctx) {
	let if_block_anchor;
	let if_block = /*type*/ ctx[0] === 'trips' && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, [dirty]) {
			if (/*type*/ ctx[0] === 'trips') {
				if (if_block) {
					
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { type } = $$props;

	$$self.$$set = $$props => {
		if ('type' in $$props) $$invalidate(0, type = $$props.type);
	};

	return [type];
}

class Skeleton extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { type: 0 });
	}
}

export default Skeleton;