import { S as SvelteComponent, i as init, s as safe_not_equal, b as assign, e as element, d as set_attributes, f as insert, l as listen, g as get_spread_update, n as noop, h as detach, r as run_all, j as compute_rest_props, c as createEventDispatcher, o as onMount, k as onDestroy, m as exclude_internal_props, p as binding_callbacks, t as tick } from '../common/index-243905b0.js';

function createCommonjsModule(fn, basedir, module) {
	return module = {
		path: basedir,
		exports: {},
		require: function (path, base) {
			return commonjsRequire(path, (base === undefined || base === null) ? module.path : base);
		}
	}, fn(module, module.exports), module.exports;
}

function commonjsRequire () {
	throw new Error('Dynamic requires are not currently supported by @rollup/plugin-commonjs');
}

var defineMaskList_1 = createCommonjsModule(function (module, exports) {
Object.defineProperty(exports, "__esModule", { value: true });
/**
 *
 * @param {String} mask
 * @param format
 * @returns {Array}
 */
function defineMaskList(mask, format) {
    if (!mask) {
        return [];
    }
    var stack = [];
    // flag if escape char is used
    var escape = false;
    mask.split('').forEach(function (maskChar) {
        var item = format[maskChar];
        // if the previous char was escape char, we should ignore next format rule, and process mask char as a regular char.
        if (escape && item) {
            item = null;
            escape = false;
        }
        if (!item) {
            // escape char
            if (!escape && maskChar === '\\') {
                escape = true;
                return;
            }
            escape = false;
            stack.push({
                char: maskChar,
            });
            return;
        }
        if (item.regexp) {
            stack.push(item);
        }
    });
    return stack;
}
exports.default = defineMaskList;
});

var CharTypesEnum = createCommonjsModule(function (module, exports) {
Object.defineProperty(exports, "__esModule", { value: true });
(function (CharTypes) {
    CharTypes[CharTypes["USER"] = 1] = "USER";
    CharTypes[CharTypes["CHAR"] = 2] = "CHAR";
    CharTypes[CharTypes["MASK"] = 3] = "MASK";
})(exports.CharTypes || (exports.CharTypes = {}));
});

var buildInputStrings_1 = createCommonjsModule(function (module, exports) {
Object.defineProperty(exports, "__esModule", { value: true });

function buildInputStrings(data, mask, input, maskChar, maskString, selection) {
    var value = [];
    var valueIndex = 0;
    var pastedIndex = 0;
    var maskedValue = '';
    var inputValuesApplied = 0;
    function processMaskPartAsChar(maskPart, pastedValuesStack, item) {
        // if user inputs value, we check it, but we don't go through all stack
        if (pastedValuesStack && pastedValuesStack[0] === maskPart.char) {
            pastedIndex++;
        }
        else {
            if ((item && (item.char === maskPart.char || item.type !== CharTypesEnum.CharTypes.USER)) || input) {
                valueIndex++;
            }
        }
        value.push({
            char: maskPart.char,
            type: CharTypesEnum.CharTypes.CHAR,
        });
        if (pastedValuesStack) {
            inputValuesApplied++;
        }
        maskedValue += maskPart.char;
    }
    function processMaskPartAsRegExp(maskPart, maskIndex, pastedValuesStack, item) {
        var part = null;
        // If we have the value inputted by user, check it.
        // We have to move through the whole stack, to find suitable
        if (pastedValuesStack) {
            var i = 0;
            while (!maskPart.regexp.test(pastedValuesStack[i]) && pastedValuesStack.length > i) {
                i++;
                pastedIndex++;
            }
            if (pastedValuesStack.length > i) {
                pastedIndex++;
                inputValuesApplied++;
                // Ignore previous value from the input
                valueIndex++;
                part = pastedValuesStack[i];
                value.push({
                    char: part,
                    type: CharTypesEnum.CharTypes.USER,
                });
                maskedValue += part;
            }
        }
        if (part) {
            return;
        }
        // User input doesn't have data or it's invalid.
        // Try to apply the previous data, or change them to the placeholder
        // if shift happened, pass excess values
        if (item && item.type === CharTypesEnum.CharTypes.CHAR && data.length > valueIndex + 1) {
            valueIndex++;
            processMaskItem(maskPart, maskIndex);
            return;
        }
        if (item && item.type === CharTypesEnum.CharTypes.USER && maskPart.regexp.test(item.char)) {
            value.push({
                char: item.char,
                type: CharTypesEnum.CharTypes.USER,
            });
            maskedValue += item.char;
            valueIndex++;
            return;
        }
        part = maskString ? maskString[maskIndex] : maskChar;
        value.push({
            char: part,
            type: CharTypesEnum.CharTypes.MASK,
        });
        if (data.length > maskIndex) {
            valueIndex++;
        }
        maskedValue += part;
    }
    // we use closures here to mutate variables, so that it increases the performance.
    function processMaskItem(maskPart, maskIndex) {
        var item = data.length > valueIndex ? data[valueIndex] : null;
        var pastedValuesStack = null;
        if (selection.start <= maskIndex && pastedIndex < input.length) {
            pastedValuesStack = input.slice(pastedIndex);
        }
        // process hardcoded char to the mask
        if (maskPart.char) {
            return processMaskPartAsChar(maskPart, pastedValuesStack, item);
        }
        // text by regexp
        if (maskPart.regexp) {
            return processMaskPartAsRegExp(maskPart, maskIndex, pastedValuesStack, item);
        }
    }
    mask.forEach(function (maskPart, maskIndex) {
        processMaskItem(maskPart, maskIndex);
    });
    return {
        value: value,
        maskedValue: maskedValue,
        inputValuesApplied: inputValuesApplied,
    };
}
exports.buildInputStrings = buildInputStrings;
});

var inputValue_1 = createCommonjsModule(function (module, exports) {
Object.defineProperty(exports, "__esModule", { value: true });


function inputValue(params) {
    var data = params.data, _a = params.input, input = _a === void 0 ? '' : _a, selection = params.selection, mask = params.mask, maskChar = params.maskChar, maskString = params.maskString;
    var _b = buildInputStrings_1.buildInputStrings(data, mask, input, maskChar, maskString, selection), value = _b.value, maskedValue = _b.maskedValue, inputValuesApplied = _b.inputValuesApplied;
    var selectionPosition = selection.start + inputValuesApplied;
    // remove all leading maskChar
    var bound = value.length - 1;
    var charsCount = 0;
    while (bound >= 0 && value[bound].type !== CharTypesEnum.CharTypes.USER) {
        if (value[bound].type === CharTypesEnum.CharTypes.MASK) {
            charsCount = 0;
        }
        if (value[bound].type === CharTypesEnum.CharTypes.CHAR) {
            charsCount++;
        }
        bound--;
    }
    bound += charsCount;
    var visibleValue = '';
    for (var i = 0; i <= bound; i++) {
        visibleValue += value[i].char;
    }
    return {
        value: value,
        visibleValue: visibleValue,
        maskedValue: maskedValue,
        selection: {
            start: selectionPosition,
            end: selectionPosition,
        },
    };
}
exports.default = inputValue;
});

var removeSelectedRange_1 = createCommonjsModule(function (module, exports) {
Object.defineProperty(exports, "__esModule", { value: true });

var copyMaskChar = function (count, maskChar) {
    var res = [];
    for (var i = 0; i < count; i++) {
        res.push({
            char: maskChar,
            type: CharTypesEnum.CharTypes.MASK,
        });
    }
    return res;
};
var pasteMaskSymbols = function (maskString, maskChar, selection) {
    if (maskString) {
        var res = [];
        for (var i = selection.start; i < selection.end; i++) {
            res.push({
                char: maskString[i],
                type: CharTypesEnum.CharTypes.MASK,
            });
        }
        return res;
    }
    return copyMaskChar(selection.end - selection.start, maskChar);
};
function removeSelectedRange(param) {
    var value = param.value, selection = param.selection, maskChar = param.maskChar, maskString = param.maskString;
    if (selection.end < selection.start) {
        var tmp = selection.end;
        selection.end = selection.start;
        selection.start = tmp;
    }
    if (selection.start === selection.end) {
        return value;
    }
    if (value.length > selection.start) {
        return value
            .slice(0, selection.start)
            .concat(pasteMaskSymbols(maskString, maskChar, selection), value.slice(selection.end, value.length));
    }
    return value;
}
exports.default = removeSelectedRange;
});

var lib = createCommonjsModule(function (module, exports) {
Object.defineProperty(exports, "__esModule", { value: true });




exports.defaults = {
    maskFormat: [
        {
            str: '0',
            regexp: /[0-9]/,
        },
        {
            str: '*',
            regexp: /./,
        },
        {
            str: 'a',
            regexp: /[a-zA-Z]/,
        },
    ],
    maskChar: '',
    showMask: false,
    removeSelectedRange: removeSelectedRange_1.default,
};
exports.createInput = function (params) {
    var maskString = params.maskString, reformat = params.reformat, _a = params.maskFormat, maskFormat = _a === void 0 ? exports.defaults.maskFormat : _a, _b = params.maskChar, maskChar = _b === void 0 ? exports.defaults.maskChar : _b;
    if (!reformat && !params.mask) {
        reformat = function (params) {
            var str = params.value.map(function (item) { return item.char; }).join('');
            return {
                value: params.value,
                visibleValue: str,
                maskedValue: str,
                selection: params.selection,
            };
        };
    }
    else if (reformat) {
        params.mask = null;
    }
    if (maskString && maskString.length !== params.mask.length) {
        throw new Error('maskString must have same length as mask');
    }
    if (maskChar.length > 1) {
        throw new Error('maskChar must have only 1 char');
    }
    var maskFormatMap;
    var selection = { start: 0, end: 0 };
    var value;
    var maskedValue;
    var visibleValue;
    var mask;
    var callbacks = [];
    var interfaceMethods = {
        subscribe: function (callback) {
            callbacks.push(callback);
        },
        unsubscribe: function (callback) {
            callbacks = callbacks.filter(function (item) { return item !== callback; });
        },
        setMaskFormat: function (maskFormat) {
            maskFormatMap = maskFormat.reduce(function (store, item) {
                store[item.str] = item;
                return store;
            }, {});
        },
        setValue: function (data) {
            var result;
            if (reformat) {
                result = reformat({
                    value: data,
                    selection: selection,
                });
            }
            else {
                var dataList = void 0;
                if (Array.isArray(data)) {
                    dataList = data;
                }
                else {
                    dataList = [];
                    for (var i = 0; i < data.length; i++) {
                        dataList.push({ char: data[i], type: CharTypesEnum.CharTypes.USER });
                    }
                }
                result = inputValue_1.default({ data: dataList, selection: selection, mask: mask, maskChar: maskChar, maskString: maskString });
            }
            applyChanges(result);
        },
        setSelection: function (newSelection) {
            selection = newSelection;
        },
        getSelection: function () {
            return {
                start: selection.start,
                end: selection.end,
            };
        },
        backspace: function () {
            interfaceMethods.removePreviosOrSelected();
        },
        removePreviosOrSelected: function () {
            if (selection.start === selection.end) {
                selection.start = selection.end - 1;
                if (selection.start < 0) {
                    selection.start = 0;
                }
            }
            interfaceMethods.input('');
        },
        removeNextOrSelected: function () {
            if (selection.start === selection.end) {
                selection.end++;
            }
            interfaceMethods.input('');
        },
        getState: function () {
            return {
                value: value,
                maskedValue: maskedValue,
                visibleValue: visibleValue,
                selection: selection,
            };
        },
        setMask: function (newMask) {
            mask = defineMaskList_1.default(newMask, maskFormatMap);
            interfaceMethods.setValue(value);
        },
        setMaskChar: function (newMaskChar) {
            if (maskChar.length > 1) {
                throw new Error('maskChar must have only 1 char');
            }
            maskChar = newMaskChar;
            interfaceMethods.setValue(value);
        },
        setMaskString: function (newMaskString) {
            if (newMaskString && newMaskString.length !== mask.length) {
                throw new Error('maskString must have the same length as mask');
            }
            maskString = newMaskString;
            interfaceMethods.setValue(value);
        },
        setReformat: function (newReformat) {
            reformat = newReformat;
            interfaceMethods.setValue(value);
        },
        paste: function (value) {
            interfaceMethods.input(value);
        },
        input: function (input) {
            var result;
            if (reformat) {
                result = reformat({ value: value, input: input, selection: selection });
            }
            else {
                var tmpValue = removeSelectedRange_1.default({ value: value, selection: selection, maskChar: maskChar, maskString: maskString });
                selection.end = selection.start;
                result = inputValue_1.default({ data: tmpValue, input: input, selection: selection, mask: mask, maskChar: maskChar, maskString: maskString });
            }
            applyChanges(result);
        },
    };
    function applyChanges(result) {
        var oldMaskedValue = maskedValue;
        var oldVisibleValue = visibleValue;
        var oldSelection = selection;
        value = result.value;
        maskedValue = result.maskedValue;
        visibleValue = result.visibleValue;
        interfaceMethods.setSelection(result.selection);
        if (oldMaskedValue !== maskedValue ||
            oldVisibleValue !== visibleValue ||
            oldSelection.start !== selection.start ||
            oldSelection.end !== selection.end) {
            notify();
        }
    }
    function notify() {
        var state = interfaceMethods.getState();
        callbacks.forEach(function (callback) {
            callback(state);
        });
    }
    interfaceMethods.setMaskFormat(maskFormat);
    mask = defineMaskList_1.default(params.mask, maskFormatMap);
    interfaceMethods.setValue(params.value);
    return interfaceMethods;
};
});

/* node_modules/svelte-input-mask/MaskInput.svelte generated by Svelte v3.59.2 */

function create_fragment(ctx) {
	let input_1;
	let mounted;
	let dispose;
	let input_1_levels = [/*$$restProps*/ ctx[8], { value: /*inputValue*/ ctx[0] }];
	let input_data = {};

	for (let i = 0; i < input_1_levels.length; i += 1) {
		input_data = assign(input_data, input_1_levels[i]);
	}

	return {
		c() {
			input_1 = element("input");
			set_attributes(input_1, input_data);
		},
		m(target, anchor) {
			insert(target, input_1, anchor);

			if ('value' in input_data) {
				input_1.value = input_data.value;
			}

			if (input_1.autofocus) input_1.focus();
			/*input_1_binding*/ ctx[18](input_1);

			if (!mounted) {
				dispose = [
					listen(input_1, "input", /*handleInput*/ ctx[2]),
					listen(input_1, "keydown", /*handleKeyDown*/ ctx[5]),
					listen(input_1, "keypress", /*handleKeyPress*/ ctx[4]),
					listen(input_1, "paste", /*handlePaste*/ ctx[3]),
					listen(input_1, "focus", /*handleFocus*/ ctx[6]),
					listen(input_1, "blur", /*handleBlur*/ ctx[7])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			set_attributes(input_1, input_data = get_spread_update(input_1_levels, [
				dirty & /*$$restProps*/ 256 && /*$$restProps*/ ctx[8],
				dirty & /*inputValue*/ 1 && input_1.value !== /*inputValue*/ ctx[0] && { value: /*inputValue*/ ctx[0] }
			]));

			if ('value' in input_data) {
				input_1.value = input_data.value;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(input_1);
			/*input_1_binding*/ ctx[18](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const omit_props_names = [
		"value","defaultValue","reformat","maskString","maskChar","mask","maskFormat","alwaysShowMask","showMask"
	];

	let $$restProps = compute_rest_props($$props, omit_props_names);
	let { value = '' } = $$props;
	let { defaultValue = undefined } = $$props;
	let { reformat = undefined } = $$props;
	let { maskString = undefined } = $$props;
	let { maskChar = lib.defaults.maskChar } = $$props;
	let { mask = lib.defaults.mask } = $$props;
	let { maskFormat = lib.defaults.maskFormat } = $$props;
	let { alwaysShowMask = false } = $$props;
	let { showMask = false } = $$props;
	const KEYBOARD = { BACKSPACE: 8, DELETE: 46 };
	const dispatch = createEventDispatcher();

	const input = lib.createInput({
		value: value || defaultValue || '',
		reformat,
		maskString,
		maskChar,
		mask,
		maskFormat
	});

	let shouldShowMask = alwaysShowMask || showMask;

	onMount(() => {
		input.subscribe(applyValue);
	});

	onDestroy(() => {
		input.unsubscribe(applyValue);
	});

	let canSetSelection = false;
	let inputValue = setupInputValue(input.getState());
	let inputEl;

	function setupInputValue({ maskedValue, visibleValue }) {
		if (shouldShowMask && (canSetSelection || alwaysShowMask)) {
			return maskedValue;
		}

		return visibleValue;
	}

	function applyValue({ maskedValue, visibleValue, selection, value }) {
		$$invalidate(0, inputValue = setupInputValue({ maskedValue, visibleValue }));
		setSelection(selection);

		dispatchChangeEvent({
			unmasked: reformat
			? value
			: value.filter(item => item.type === 1).map(item => item.char).join(''),
			maskedValue,
			visibleValue
		});
	}

	async function setSelection({ start, end }) {
		if (!canSetSelection) {
			return;
		}

		await tick();
		inputEl.setSelectionRange(start, end);
		const raf = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || (fn => setTimeout(fn, 0));

		// For android
		raf(() => inputEl.setSelectionRange(start, end));
	}

	function setupSelection() {
		input.setSelection({
			start: inputEl.selectionStart,
			end: inputEl.selectionEnd
		});
	}

	function getValue() {
		if (showMask && (canSetSelection || alwaysShowMask)) {
			return input.getState().maskedValue;
		} else {
			return input.getState().visibleValue;
		}
	}

	function handleInput(e) {
		const prevValue = getValue();

		// fix conflict by update value in mask model
		if (e.target.value !== prevValue) {
			input.input(e.data);
			setSelection(input.getSelection());

			// Timeout needed for IE
			setTimeout(() => setSelection(input.getSelection()), 0);
		}
	}

	function handlePaste(e) {
		e.preventDefault();
		setupSelection();

		// getData value needed for IE also works in FF & Chrome
		input.paste(e.clipboardData.getData('Text'));

		setSelection(input.getSelection());

		// Timeout needed for IE
		setTimeout(() => setSelection(input.getSelection()), 0);
	}

	function handleKeyPress(e) {
		if (e.metaKey || e.altKey || e.ctrlKey || e.key === 'Enter') {
			return;
		}

		e.preventDefault();
		setupSelection();
		input.input(e.key || e.data || String.fromCharCode(e.which));
		setSelection(input.getSelection());
	}

	function handleKeyDown(e) {
		if (e.which === KEYBOARD.BACKSPACE) {
			e.preventDefault();
			setupSelection();
			input.removePreviosOrSelected();
			setSelection(input.getSelection());
		}

		if (e.which === KEYBOARD.DELETE) {
			e.preventDefault();
			setupSelection();
			input.removeNextOrSelected();
			setSelection(input.getSelection());
		}
	}

	function handleFocus(e) {
		canSetSelection = true;
		dispatch('focus', e);
	}

	function handleBlur(e) {
		canSetSelection = false;
		dispatch('blur', e);
	}

	function dispatchChangeEvent({ unmasked, maskedValue, visibleValue }) {
		dispatch('change', {
			element: inputEl,
			inputState: {
				unmaskedValue: unmasked,
				maskedValue,
				visibleValue
			}
		});
	}

	function input_1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			inputEl = $$value;
			$$invalidate(1, inputEl);
		});
	}

	$$self.$$set = $$new_props => {
		$$props = assign(assign({}, $$props), exclude_internal_props($$new_props));
		$$invalidate(8, $$restProps = compute_rest_props($$props, omit_props_names));
		if ('value' in $$new_props) $$invalidate(9, value = $$new_props.value);
		if ('defaultValue' in $$new_props) $$invalidate(10, defaultValue = $$new_props.defaultValue);
		if ('reformat' in $$new_props) $$invalidate(11, reformat = $$new_props.reformat);
		if ('maskString' in $$new_props) $$invalidate(12, maskString = $$new_props.maskString);
		if ('maskChar' in $$new_props) $$invalidate(13, maskChar = $$new_props.maskChar);
		if ('mask' in $$new_props) $$invalidate(14, mask = $$new_props.mask);
		if ('maskFormat' in $$new_props) $$invalidate(15, maskFormat = $$new_props.maskFormat);
		if ('alwaysShowMask' in $$new_props) $$invalidate(16, alwaysShowMask = $$new_props.alwaysShowMask);
		if ('showMask' in $$new_props) $$invalidate(17, showMask = $$new_props.showMask);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*alwaysShowMask, showMask*/ 196608) {
			 shouldShowMask = alwaysShowMask || showMask;
		}

		if ($$self.$$.dirty & /*reformat*/ 2048) {
			 input.setReformat(reformat);
		}

		if ($$self.$$.dirty & /*maskFormat*/ 32768) {
			 input.setMaskFormat(maskFormat);
		}

		if ($$self.$$.dirty & /*mask*/ 16384) {
			 input.setMask(mask);
		}

		if ($$self.$$.dirty & /*maskString*/ 4096) {
			 input.setMaskString(maskString);
		}

		if ($$self.$$.dirty & /*maskChar*/ 8192) {
			 input.setMaskChar(maskChar);
		}

		if ($$self.$$.dirty & /*value*/ 512) {
			 value !== undefined && input.setValue(value);
		}
	};

	return [
		inputValue,
		inputEl,
		handleInput,
		handlePaste,
		handleKeyPress,
		handleKeyDown,
		handleFocus,
		handleBlur,
		$$restProps,
		value,
		defaultValue,
		reformat,
		maskString,
		maskChar,
		mask,
		maskFormat,
		alwaysShowMask,
		showMask,
		input_1_binding
	];
}

class MaskInput extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			value: 9,
			defaultValue: 10,
			reformat: 11,
			maskString: 12,
			maskChar: 13,
			mask: 14,
			maskFormat: 15,
			alwaysShowMask: 16,
			showMask: 17
		});
	}
}

export default MaskInput;
