import './Loading.svelte.css';
/* src/components/Loading.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import Erb from './Erb.svelte.js';

function create_fragment(ctx) {
	let div;
	let erb;
	let t0;
	let h2;
	let t1;
	let t2;
	let p;
	let t3;
	let current;

	erb = new Erb({
			props: {
				customClass: "w-28 animate__animated animate__pulse animate__infinite"
			}
		});

	return {
		c() {
			div = element("div");
			create_component(erb.$$.fragment);
			t0 = space();
			h2 = element("h2");
			t1 = text(/*heading*/ ctx[0]);
			t2 = space();
			p = element("p");
			t3 = text(/*description*/ ctx[1]);
			attr(h2, "class", "svelte-oudpph");
			attr(p, "class", "svelte-oudpph");
			attr(div, "class", "loading-overlay svelte-oudpph");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(erb, div, null);
			append(div, t0);
			append(div, h2);
			append(h2, t1);
			append(div, t2);
			append(div, p);
			append(p, t3);
			current = true;
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*heading*/ 1) set_data(t1, /*heading*/ ctx[0]);
			if (!current || dirty & /*description*/ 2) set_data(t3, /*description*/ ctx[1]);
		},
		i(local) {
			if (current) return;
			transition_in(erb.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(erb.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(erb);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { heading = 'Načítavam...' } = $$props;
	let { description = 'Môže to trvať niekoľko sekúnd, prosím, nezatvárajte toto okno.' } = $$props;

	$$self.$$set = $$props => {
		if ('heading' in $$props) $$invalidate(0, heading = $$props.heading);
		if ('description' in $$props) $$invalidate(1, description = $$props.description);
	};

	return [heading, description];
}

class Loading extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { heading: 0, description: 1 });
	}
}

export default Loading;