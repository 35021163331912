import './Step.svelte.css';
/* src/components/Step.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_slot,
	detach,
	element,
	get_all_dirty_from_scope,
	get_slot_changes,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	space,
	svg_element,
	text,
	toggle_class,
	transition_in,
	transition_out,
	update_slot_base
} from "../../_snowpack/pkg/svelte/internal.js";

import { createEventDispatcher } from '../../_snowpack/pkg/svelte.js';
const get_nextBtn_slot_changes = dirty => ({});
const get_nextBtn_slot_context = ctx => ({});

// (63:11) Ďalej 
function fallback_block(ctx) {
	let t;
	let svg;
	let path;

	return {
		c() {
			t = text("Ďalej ");
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "fill-rule", "evenodd");
			attr(path, "d", "M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z");
			attr(path, "clip-rule", "evenodd");
			attr(svg, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg, "class", "h-5 w-5 ml-12");
			attr(svg, "viewBox", "0 0 20 20");
			attr(svg, "fill", "currentColor");
		},
		m(target, anchor) {
			insert(target, t, anchor);
			insert(target, svg, anchor);
			append(svg, path);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(t);
			if (detaching) detach(svg);
		}
	};
}

// (77:6) {#if tooltip}
function create_if_block(ctx) {
	let span;
	let t;

	return {
		c() {
			span = element("span");
			t = text(/*tooltip*/ ctx[4]);
			attr(span, "class", "tooltip svelte-1680raa");
		},
		m(target, anchor) {
			insert(target, span, anchor);
			append(span, t);
		},
		p(ctx, dirty) {
			if (dirty & /*tooltip*/ 16) set_data(t, /*tooltip*/ ctx[4]);
		},
		d(detaching) {
			if (detaching) detach(span);
		}
	};
}

function create_fragment(ctx) {
	let section2;
	let div;
	let section0;
	let h2;
	let t0;
	let t1;
	let t2;
	let section1;
	let aside0;
	let button0;
	let svg;
	let path;
	let t3;
	let span;
	let button0_class_value;
	let t5;
	let aside1;
	let button1;
	let button1_class_value;
	let t6;
	let section2_class_value;
	let current;
	let mounted;
	let dispose;
	const default_slot_template = /*#slots*/ ctx[10].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[9], null);
	const nextBtn_slot_template = /*#slots*/ ctx[10].nextBtn;
	const nextBtn_slot = create_slot(nextBtn_slot_template, ctx, /*$$scope*/ ctx[9], get_nextBtn_slot_context);
	const nextBtn_slot_or_fallback = nextBtn_slot || fallback_block(ctx);
	let if_block = /*tooltip*/ ctx[4] && create_if_block(ctx);

	return {
		c() {
			section2 = element("section");
			div = element("div");
			section0 = element("section");
			h2 = element("h2");
			t0 = text(/*sectionName*/ ctx[5]);
			t1 = space();
			if (default_slot) default_slot.c();
			t2 = space();
			section1 = element("section");
			aside0 = element("aside");
			button0 = element("button");
			svg = svg_element("svg");
			path = svg_element("path");
			t3 = space();
			span = element("span");
			span.textContent = "Naspäť";
			t5 = space();
			aside1 = element("aside");
			button1 = element("button");
			if (nextBtn_slot_or_fallback) nextBtn_slot_or_fallback.c();
			t6 = space();
			if (if_block) if_block.c();
			attr(section0, "class", "text-2xl text-center text-primary dark:text-primary-light py-2 font-bold mb-10 xl:mb-16");
			attr(div, "class", "px-5 sm:px-10 xl:px-20 py-10 xl:pt-16 xl:pb-10");
			attr(path, "fill-rule", "evenodd");
			attr(path, "d", "M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z");
			attr(path, "clip-rule", "evenodd");
			attr(svg, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg, "class", "h-5 w-5 sm:mr-3");
			attr(svg, "viewBox", "0 0 20 20");
			attr(svg, "fill", "currentColor");
			attr(span, "class", "hidden sm:inline-block");
			attr(button0, "type", "button");
			attr(button0, "class", button0_class_value = "border dark:border-opacity-20 hover:border-primary dark:hover:bg-primary-light py-4 px-4 sm:px-6 text-primary dark:text-primary-light hover:text-white bg-white-pure dark:bg-secondary-light hover:bg-primary rounded-xl flex items-center font-semibold cursor-pointer " + /*previousBtnClass*/ ctx[0] + " svelte-1680raa");
			attr(button1, "type", /*nextBtnType*/ ctx[1]);
			attr(button1, "class", button1_class_value = "border-primary dark:border-primary-light py-4 px-5 sm:px-6 text-white bg-primary dark:bg-primary-light rounded-xl flex items-center font-semibold cursor-pointer " + /*nextBtnClass*/ ctx[3] + " svelte-1680raa");
			button1.disabled = /*nextBtnDisabled*/ ctx[2];
			attr(aside1, "class", "relative svelte-1680raa");
			toggle_class(aside1, "hasTooltip", /*tooltip*/ ctx[4]);
			attr(section1, "class", "flex justify-between px-5 sm:px-10 py-8");
			attr(section2, "class", section2_class_value = "form__step " + /*sectionClass*/ ctx[6] + " svelte-1680raa");
		},
		m(target, anchor) {
			insert(target, section2, anchor);
			append(section2, div);
			append(div, section0);
			append(section0, h2);
			append(h2, t0);
			append(div, t1);

			if (default_slot) {
				default_slot.m(div, null);
			}

			append(section2, t2);
			append(section2, section1);
			append(section1, aside0);
			append(aside0, button0);
			append(button0, svg);
			append(svg, path);
			append(button0, t3);
			append(button0, span);
			append(section1, t5);
			append(section1, aside1);
			append(aside1, button1);

			if (nextBtn_slot_or_fallback) {
				nextBtn_slot_or_fallback.m(button1, null);
			}

			append(aside1, t6);
			if (if_block) if_block.m(aside1, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*previousStep*/ ctx[8]),
					listen(button1, "click", /*nextStep*/ ctx[7])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*sectionName*/ 32) set_data(t0, /*sectionName*/ ctx[5]);

			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 512)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[9],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[9])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[9], dirty, null),
						null
					);
				}
			}

			if (!current || dirty & /*previousBtnClass*/ 1 && button0_class_value !== (button0_class_value = "border dark:border-opacity-20 hover:border-primary dark:hover:bg-primary-light py-4 px-4 sm:px-6 text-primary dark:text-primary-light hover:text-white bg-white-pure dark:bg-secondary-light hover:bg-primary rounded-xl flex items-center font-semibold cursor-pointer " + /*previousBtnClass*/ ctx[0] + " svelte-1680raa")) {
				attr(button0, "class", button0_class_value);
			}

			if (nextBtn_slot) {
				if (nextBtn_slot.p && (!current || dirty & /*$$scope*/ 512)) {
					update_slot_base(
						nextBtn_slot,
						nextBtn_slot_template,
						ctx,
						/*$$scope*/ ctx[9],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[9])
						: get_slot_changes(nextBtn_slot_template, /*$$scope*/ ctx[9], dirty, get_nextBtn_slot_changes),
						get_nextBtn_slot_context
					);
				}
			}

			if (!current || dirty & /*nextBtnType*/ 2) {
				attr(button1, "type", /*nextBtnType*/ ctx[1]);
			}

			if (!current || dirty & /*nextBtnClass*/ 8 && button1_class_value !== (button1_class_value = "border-primary dark:border-primary-light py-4 px-5 sm:px-6 text-white bg-primary dark:bg-primary-light rounded-xl flex items-center font-semibold cursor-pointer " + /*nextBtnClass*/ ctx[3] + " svelte-1680raa")) {
				attr(button1, "class", button1_class_value);
			}

			if (!current || dirty & /*nextBtnDisabled*/ 4) {
				button1.disabled = /*nextBtnDisabled*/ ctx[2];
			}

			if (/*tooltip*/ ctx[4]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(aside1, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (!current || dirty & /*tooltip*/ 16) {
				toggle_class(aside1, "hasTooltip", /*tooltip*/ ctx[4]);
			}

			if (!current || dirty & /*sectionClass*/ 64 && section2_class_value !== (section2_class_value = "form__step " + /*sectionClass*/ ctx[6] + " svelte-1680raa")) {
				attr(section2, "class", section2_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			transition_in(nextBtn_slot_or_fallback, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			transition_out(nextBtn_slot_or_fallback, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(section2);
			if (default_slot) default_slot.d(detaching);
			if (nextBtn_slot_or_fallback) nextBtn_slot_or_fallback.d(detaching);
			if (if_block) if_block.d();
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { previousBtnClass } = $$props;
	let { nextBtnType = 'button' } = $$props;
	let { nextBtnDisabled } = $$props;
	let { nextBtnClass } = $$props;
	let { tooltip = false } = $$props;
	let { sectionName = 'Názov sekcie' } = $$props;
	let { sectionClass } = $$props;
	const dispatch = createEventDispatcher();

	const nextStep = () => {
		dispatch('nextStep');
	};

	const previousStep = () => {
		dispatch('previousStep');
	};

	$$self.$$set = $$props => {
		if ('previousBtnClass' in $$props) $$invalidate(0, previousBtnClass = $$props.previousBtnClass);
		if ('nextBtnType' in $$props) $$invalidate(1, nextBtnType = $$props.nextBtnType);
		if ('nextBtnDisabled' in $$props) $$invalidate(2, nextBtnDisabled = $$props.nextBtnDisabled);
		if ('nextBtnClass' in $$props) $$invalidate(3, nextBtnClass = $$props.nextBtnClass);
		if ('tooltip' in $$props) $$invalidate(4, tooltip = $$props.tooltip);
		if ('sectionName' in $$props) $$invalidate(5, sectionName = $$props.sectionName);
		if ('sectionClass' in $$props) $$invalidate(6, sectionClass = $$props.sectionClass);
		if ('$$scope' in $$props) $$invalidate(9, $$scope = $$props.$$scope);
	};

	return [
		previousBtnClass,
		nextBtnType,
		nextBtnDisabled,
		nextBtnClass,
		tooltip,
		sectionName,
		sectionClass,
		nextStep,
		previousStep,
		$$scope,
		slots
	];
}

class Step extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			previousBtnClass: 0,
			nextBtnType: 1,
			nextBtnDisabled: 2,
			nextBtnClass: 3,
			tooltip: 4,
			sectionName: 5,
			sectionClass: 6
		});
	}
}

export default Step;